import styled from 'styled-components';

interface Props {
  isActive: boolean;
}

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 310px;
  width: 310px;
  padding: 32px 20px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.blue50 : theme.color.grey50};
  border: ${({ theme, isActive }) =>
    `1px solid ${isActive ? theme.color.blue400 : 'transparent'}`};
  border-radius: 12px;
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};

  &:hover {
    box-shadow: ${({ isActive }) =>
      isActive ? 'none' : '0 0 3px 0 rgba(11, 37, 80, 0.4)'};
  }
`;

export const PackageInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: 500;
  font-size: 16px;
  align-items: flex-start;
  justify-content: space-between;

  label {
    margin-right: 6px;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.color.grey900};
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.color.grey900};
    margin-top: 4px;
    height: 40px;
  }
`;

import {
  UserChangePasswordDto,
  UserLoginDto,
  UserRefreshTokenDto,
  UserSetPasswordDto,
} from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UsersActions } from 'applicaiton/constants/actions';
import {
  getToken,
  setUsersRefreshTokenSessionStorage,
} from 'applicaiton/sessionStorage/auth';
import { AxiosError } from 'axios';
import { UsersAPI } from 'integration/api/users';
import { setIsValidCode } from './UsersSlice';
import { authService } from 'common';
import { ForgotPasswordDto } from 'common/auth/forgotPassword/types';

export const usersLogin = createAsyncThunk(
  UsersActions.usersLogin,
  async (loginData: UserLoginDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersLogin(loginData);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const usersRefreshToken = createAsyncThunk(
  UsersActions.usersRefreshToken,
  async (refreshTokenData: UserRefreshTokenDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersRefreshToken(refreshTokenData);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const usersRefreshExpiredToken = async (
  refreshTokenData: UserRefreshTokenDto,
) => {
  try {
    const data = await UsersAPI.usersRefreshToken(refreshTokenData);
    setUsersRefreshTokenSessionStorage(data.data);
    return data.data;
  } catch (e) {
    console.error(e);
    authService.cleanStorage();
  }
};

export const usersForgotPassword = createAsyncThunk(
  UsersActions.usersForgotPassword,
  async (forgotPasswordData: ForgotPasswordDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersForgotPassword(forgotPasswordData);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error);
    }
  },
);

export const usersCreateNewPassword = createAsyncThunk(
  UsersActions.usersCreateNewPassword,
  async (setPasswordData: UserSetPasswordDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersCreateNewPassword(setPasswordData);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const usersVerifyCode = createAsyncThunk(
  UsersActions.usersVerificationCode,
  async (
    usersVerifyCodeData: { email: string; code: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await UsersAPI.usersVerifyCode(usersVerifyCodeData);

      data ? dispatch(setIsValidCode(true)) : dispatch(setIsValidCode(false));
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const userChangePassword = createAsyncThunk(
  UsersActions.userChangePassword,
  async (data: UserChangePasswordDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const { data: responseData } = await UsersAPI.userChangePassword(
        data,
        token,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const signOut = createAsyncThunk(
  UsersActions.signOut,
  async (_, { rejectWithValue }) => {
    try {
      const { data: responseData } = await UsersAPI.signOut();
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const checkUsersEmailExisting = createAsyncThunk(
  UsersActions.checkUsersEmailExisting,
  async (email: string, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.checkUserEmailExisting(email);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const setUserLanguage = createAsyncThunk(
  UsersActions.setUserLanguage,
  async (languageIso: string, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.setUserLanguage(languageIso);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getUserProfile = createAsyncThunk(
  UsersActions.getUserProfile,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.getUserProfile();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

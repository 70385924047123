import i18n from 'i18n';
import * as yup from 'yup';

export const getSlotsValidation = () => {
  return yup
    .array()
    .of(
      yup.object().shape({
        day: yup.string(),
        slots: yup.array().of(
          yup.object().shape({
            startTime: yup.string(),
            endTime: yup.string(),
          }),
        ),
        isEnabled: yup.boolean(),
      }),
    )
    .test({
      name: 'is-valid-hours',
      test(value, ctx) {
        const hours = value?.filter((item) =>
          item.slots?.some(
            (slot) => slot.startTime?.length || slot.endTime?.length,
          ),
        );
        if (!hours) return false;
        const hasHoursError = hours?.some(
          (item) =>
            item?.isEnabled &&
            item?.slots?.some(
              (slot) => !slot?.startTime?.length || !slot?.endTime?.length,
            ),
        );
        if (hasHoursError) {
          return ctx.createError({
            type: 'required',
            message: i18n.t('errors.required') || '',
          });
        } else {
          return true;
        }
      },
    });
};

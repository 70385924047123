import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppointmentsActions } from '../../../constants/actions';
import { AxiosError } from 'axios';
import { AppointmentsApi } from 'integration/api/appointments';
import {
  AppointmentCreationDto,
  AppointmentStatusUpdateDto,
  AppointmentUpdateDto,
} from '@docbay/schemas';
import { WaitingListProps, Props } from './models';
import { PaginationProps } from 'common/types/pagination';

export const fetchPatientAppointmentsBrief = createAsyncThunk(
  AppointmentsActions.fetchPatientsAppointments,
  async (params: Props, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await AppointmentsApi.fetchPatientAppointmentsBrief(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchAppointmentsWaitingList = createAsyncThunk(
  AppointmentsActions.fetchAppointmentsWaitingList,
  async (params: WaitingListProps, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await AppointmentsApi.fetchAppointmentsWaitingList(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createAppointment = createAsyncThunk(
  AppointmentsActions.createAppointment,
  async (data: AppointmentCreationDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await AppointmentsApi.createAppointment(
        data,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchAppointmentPatients = createAsyncThunk(
  AppointmentsActions.fetchAppointmentPatients,
  async (params: { search: string }, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await AppointmentsApi.fetchAppointmentPatients(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteAppointment = createAsyncThunk(
  AppointmentsActions.deleteAppointment,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await AppointmentsApi.deleteAppointment(
        id,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchAppointmentById = createAsyncThunk(
  AppointmentsActions.fetchAppointmentById,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await AppointmentsApi.fetchAppointmentById(
        id,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const editAppointment = createAsyncThunk(
  AppointmentsActions.editAppointment,
  async (
    params: { id: string; data: AppointmentUpdateDto },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } = await AppointmentsApi.editAppointment(
        params.id,
        params.data,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const endAppointment = createAsyncThunk(
  AppointmentsActions.endAppointment,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await AppointmentsApi.endAppointment(id);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateAppointmentStatus = createAsyncThunk(
  AppointmentsActions.updateAppointmentStatus,
  async (
    { id, data }: { id: string; data: AppointmentStatusUpdateDto },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } =
        await AppointmentsApi.updateAppointmentStatus(id, data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchAppointmentRequestList = createAsyncThunk(
  AppointmentsActions.fetchAppointmentRequestList,
  async (params: PaginationProps, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await AppointmentsApi.fetchAppointmentRequestList(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteAppointmentRequest = createAsyncThunk(
  AppointmentsActions.deleteAppointmentRequest,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await AppointmentsApi.deleteAppointmentRequest(id);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

import React, { FC, useEffect, useMemo, useState } from 'react';
import { TimeRange } from 'common/components/index';
import { OpeningHours, TimeSlots } from 'common/types/clinics';
import {
  Wrapper,
  SlotsWrapper,
  ScheduleFieldItem,
  SlotsContainer,
  ReplicateButton,
} from './styles';
import { getSchedule } from 'common/helpers/getSchedule';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { useTranslation } from 'react-i18next';
import { ClinicScheduleProps } from './modules';

const OnlineHours: FC<ClinicScheduleProps> = ({
  setValue,
  clearErrors,
  enabledWorkingHours,
  hasError,
}) => {
  const { t } = useTranslation();
  const [openingHours, setOpeningHours] = useState<OpeningHours[]>(() =>
    getSchedule({ disableAll: true }),
  );

  useEffect(() => {
    if (!enabledWorkingHours?.length) {
      setOpeningHours(getSchedule({ disableAll: true }));
    } else {
      const defaultSchedule = getSchedule({ disableAll: true });

      const result = defaultSchedule.map((day) => {
        return {
          ...day,
          isEnabled: false,
          ...(enabledWorkingHours.find((day2) => day2.day === day.day) || {}),
        };
      });

      setOpeningHours(result);
    }
  }, [enabledWorkingHours]);

  const handleAddSlot = (day: string) => {
    const newSlot = {
      startTime: '',
      endTime: '',
    };

    setOpeningHours((prev) => [
      ...prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            slots: [...item.slots, newSlot],
          };
        }
        return item;
      }),
    ]);
  };

  const handleEditSlot = (day: string, index: number, value: TimeSlots) => {
    const openingHoursEdited = openingHours?.map((item) => {
      if (item.day === day) {
        return {
          ...item,
          slots: item.slots.map((slot, i) => {
            if (index === i) {
              return { ...slot, ...value };
            }
            return slot;
          }),
        };
      }
      return item;
    });

    setOpeningHours(openingHoursEdited);
  };

  const handleEnableSlot = (day: string, value: boolean) => {
    setOpeningHours((prev) =>
      prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            isEnabled: value,
          };
        }
        return item;
      }),
    );
  };

  const handleRemoveSlot = (day: string, index: number) => {
    setOpeningHours((prev) =>
      prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            slots: item.slots.filter((slot, i) => index !== i),
          };
        }
        return item;
      }),
    );
  };

  const isStateChanged = useMemo(() => {
    const hasChanges = compareObjectsChanges(
      { openingHours },
      {
        openingHours: enabledWorkingHours,
      },
    );
    return hasChanges;
  }, [openingHours, enabledWorkingHours]);

  const scheduleValidation = (hours: OpeningHours[]) => {
    const errorFields = hours.filter((item) =>
      item.slots.some(
        (item) =>
          !item.startTime ||
          !item.endTime ||
          item.startTime === 'Invalid Date' ||
          item.endTime === 'Invalid Date' ||
          item.startTime > item.endTime,
      ),
    );

    return !!errorFields.length;
  };

  const handleScheduleChange = (hours: OpeningHours[]) => {
    const newArr = hours.filter((item) => item.isEnabled);
    const hasErrors: boolean = scheduleValidation(newArr);

    setValue('hours', newArr);
    if (!hasErrors) {
      clearErrors('hours');
    }
  };

  useEffect(() => {
    if (openingHours.length && isStateChanged) {
      handleScheduleChange(openingHours);
    }
  }, [openingHours]);

  const handleAllDays = () => {
    const firstDay = openingHours.find((item) => item.day === 'Monday')!;

    const currentOpeningHours = openingHours.map((day) => {
      if (day.day === 'Saturday' || day.day === 'Sunday') {
        return day;
      }

      return {
        day: day.day,
        isEnabled: firstDay.isEnabled!,
        slots: firstDay.slots!,
      };
    });

    setValue('hours', currentOpeningHours);
    clearErrors('hours');
    setOpeningHours(currentOpeningHours);
  };

  const isDisabledButton = useMemo(() => {
    if (!openingHours.length) return true;

    const firstDay = openingHours.find((item) => item.day === 'Monday');

    return !firstDay?.slots[0]?.startTime || !firstDay?.slots[0]?.endTime;
  }, [openingHours]);

  return (
    <Wrapper>
      <ScheduleFieldItem>
        <label>{t('professional_profile.subscriptions.online_hours')}</label>
        <span>
          {t('professional_profile.subscriptions.online_hours_description')}
        </span>
        <SlotsContainer>
          <SlotsWrapper>
            {openingHours.map((item, index) => (
              <TimeRange
                key={index}
                day={item.day}
                slots={item.slots}
                isEnabled={item.isEnabled}
                handleAddSlot={handleAddSlot}
                handleEditSlot={handleEditSlot}
                handleRemoveSlot={handleRemoveSlot}
                handleEnableSlot={handleEnableSlot}
                hasError={hasError}
              />
            ))}
          </SlotsWrapper>
          <div>
            <ReplicateButton
              disabled={isDisabledButton}
              onClick={handleAllDays}
            >
              {t('clinicsConfiguration.clinics.replicate')}
            </ReplicateButton>
          </div>
        </SlotsContainer>
      </ScheduleFieldItem>
    </Wrapper>
  );
};

export default OnlineHours;

import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { commonColors } from 'applicaiton/theme';

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 90vw;
  height: 90vh;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  overflow-y: auto;
`;

export const FormsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: auto;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
  color: ${({ theme }) => theme.color.grey900};
  font-family: AcidGrotesk-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.2px;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.color.grey700};
  }
`;

export const SubscriptionInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
  background: ${({ theme }) => theme.color.grey50};
`;

export const Row = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding: 32px;
  border-top: 1px solid ${({ theme }) => theme.color.grey200};
  justify-content: flex-end;

  button {
    width: 150px;

    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const InfoStyled = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  background: ${commonColors.success4};

  p {
    color: ${commonColors.grey700};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.07px;
  }
`;

export const SelectTypeStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > h2 {
    color: ${commonColors.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

import React, { FC, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { SecondaryButton, PrimaryButton, Loader } from 'common/components';

import { ConfirmModalProps } from './models';
import { FormStyled, ButtonsSection, ModalStyled } from './styles';
import { Modal } from '@mui/material';
import { CloseIcon } from 'applicaiton/assets';

const ConfirmModal: FC<ConfirmModalProps> = ({
  opened,
  onClose,
  onSubmit,
  children,
  styleType = 'default',
  isLoading,
}) => {
  const { t } = useTranslation();
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <Modal open={opened} onClose={onClose}>
      <>
        {isLoading && <Loader />}
        <ModalStyled>
          <CloseIcon onClick={onClose} className="modal-close-icon" />
          <div>
            <FormStyled onSubmit={handleSubmit}>
              {children}
              <ButtonsSection>
                <SecondaryButton type="button" onClick={onClose}>
                  {t('cancel')}
                </SecondaryButton>
                <PrimaryButton type="submit" styleType={styleType}>
                  {t('confirm')}
                </PrimaryButton>
              </ButtonsSection>
            </FormStyled>
          </div>
        </ModalStyled>
      </>
    </Modal>
  );
};

export default ConfirmModal;

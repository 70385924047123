import React, { FC, JSXElementConstructor } from 'react';
import { MenuItemStyled, NotificationIcon } from './styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { setNextPath } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { useDetectPathChanged } from 'common/hooks/useDetectPathChanged';
import { resetAgendaFiltersSessionStorage } from 'applicaiton/sessionStorage/scheduler';
import { resetScheduler } from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import { PathNames } from 'applicaiton/routes';
import { DocBaySupportLink } from 'applicaiton';
import { ReactComponent as RedDot } from 'features/feature-aside-menu/assets/red-dot.svg';

interface MenuItemProps {
  path: string;
  name: string;
  Image?: JSXElementConstructor<any>;
  isNotificationItem?: boolean;
  isDocBoxItem?: boolean;
}

const MenuItem: FC<MenuItemProps> = ({
  path,
  name,
  Image,
  isNotificationItem,
  isDocBoxItem,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state.notificationsInbox);

  const unreadMessages = notifications.filter((item) => !item.isRead).length;
  const locationEndIndex = location.pathname.slice(1).indexOf('/');
  const locationMainPath = location.pathname.slice(0, locationEndIndex);

  const isCurrentPage = path.includes(locationMainPath);
  const hasUnsavedChanges = useDetectPathChanged(path);
  const isSupportItem = path === PathNames.support;

  const handleChangePath = () => {
    const isGoToAgenda =
      path === PathNames.clinicOwnerSchedule || path === PathNames.schedule;
    if (isGoToAgenda && !isCurrentPage) {
      resetAgendaFiltersSessionStorage();
      dispatch(resetScheduler());
    }
    if (!isCurrentPage && !hasUnsavedChanges) {
      navigate(path);
    }
    if (!isCurrentPage && hasUnsavedChanges) {
      dispatch(setNextPath(path));
    }
  };

  return (
    <MenuItemStyled>
      {isSupportItem ? (
        <Link to={DocBaySupportLink} target="_blank">
          <button type="button" className={`${isCurrentPage ? 'active' : ''}`}>
            {Image ? (
              <div className="menu_item">
                <Image />
                <p>{name}</p>
              </div>
            ) : (
              name
            )}
          </button>
        </Link>
      ) : (
        <button
          onClick={handleChangePath}
          className={`${isCurrentPage ? 'active' : ''}`}
        >
          {Image ? (
            <div className={'menu_item'}>
              <div
                className={`image-wrapper ${
                  isDocBoxItem ? 'doc-box-icon' : ''
                } ${isNotificationItem ? 'stroke-icon' : ''}`}
              >
                {isNotificationItem && unreadMessages ? (
                  <NotificationIcon>
                    <Image />
                    <RedDot />
                  </NotificationIcon>
                ) : (
                  <Image />
                )}
              </div>

              <p>{name}</p>
            </div>
          ) : (
            name
          )}
        </button>
      )}
    </MenuItemStyled>
  );
};

export default MenuItem;

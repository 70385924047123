import styled from '@emotion/styled';
import { commonColors } from 'applicaiton/theme';

interface StyledProps {
  showMenu: boolean;
}

export const Wrapper = styled.div<StyledProps>`
  display: ${({ showMenu }) => (showMenu ? 'flex' : 'none')};
  flex-direction: column;
  gap: 16px;
  padding: 0 8px;
  min-width: 260px;
  width: 260px;
  max-height: 100vh;
  background: ${commonColors.background};

  .accordion-list {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    > div {
      button {
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
        height: 36px;
        padding: 8px;
        color: ${commonColors.grey700};
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        letter-spacing: 0.08px;
        background: transparent;
        border-radius: 4px;
        border: none;
        text-align: start;
        cursor: pointer;
        :hover {
          background: ${commonColors.blue100};
        }
      }
    }
    .active {
      border-radius: 4px;
      background: ${commonColors.blue200};
      color: ${commonColors.grey900};
      font-weight: 700;
    }
  }
`;

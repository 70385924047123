import { commonColors } from 'applicaiton/theme';
import styled from 'styled-components';

interface StyledProps {
  width?: string;
}

export const ModalStyled = styled.div<StyledProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  min-width: 500px;
  width: ${({ width }) => (width?.length ? width : '500px')};
  padding: 32px;
  flex-direction: column;
  border-radius: 8px;
  background: ${commonColors.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);

  > svg {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: 12px;
    cursor: pointer;

    path {
      fill: ${commonColors.grey500};
    }
  }
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ButtonsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 32px;

  > button {
    width: 120px;
  }
`;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PathNames } from 'applicaiton/routes';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';

import { Header } from 'common/components';
import PageSwitcher from 'common/components/PageSwitcher';
import { featuresPermissions } from 'common/helpers/featuresPermissions';

import WaitingAppointmentsTable from './components/WaitingAppointmentsTable';
import { Wrapper } from './styles';

const WaitingList: FC = () => {
  const { t } = useTranslation();
  const { hasAppointmentRequestPermission } = featuresPermissions();
  const userRole = getUserRole();
  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);

  const showSwitcher = isClinicOwnerRole || hasAppointmentRequestPermission;

  return (
    <Wrapper>
      <Header />
      {showSwitcher && (
        <PageSwitcher>
          {isClinicOwnerRole && (
            <Link to={PathNames.myPatients}>
              {t('clinicsConfiguration.aside_menu.my_patients_path')}
            </Link>
          )}
          <div>{t('clinicsConfiguration.aside_menu.waiting_list')}</div>
          {hasAppointmentRequestPermission && (
            <Link to={PathNames.requestList}>
              {t('clinicsConfiguration.aside_menu.request_list')}
            </Link>
          )}
        </PageSwitcher>
      )}
      <h1>{t('waiting_list.title')}</h1>
      <WaitingAppointmentsTable />
    </Wrapper>
  );
};

export default WaitingList;

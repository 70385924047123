import React, { FC, useEffect, useMemo, useState } from 'react';
import Modal from '@mui/material/Modal';
import {
  StyledModal,
  Title,
  StyledCloseIcon,
  SubscriptionInfo,
  Row,
  ButtonWrapper,
  FormsWrapper,
  InfoStyled,
  SelectTypeStyled,
} from './styles';
import {
  Dropdown,
  Input,
  RadioButton,
  SecondaryButton,
} from 'common/components';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'common/hooks/redux';
import { useSubscriptionsForm } from '../../hooks/useSubscriptionsForm';
import { Option } from 'common/components/Dropdown/models';
import { getWeekDurationOptions } from 'common/helpers/getWeekDuration';
import { getMonthDurationOptions } from 'common/helpers/getMonthDurationOptions';
import PrimaryButton from 'common/components/PrimaryButton';
import { v4 as uuid } from 'uuid';
import { CreateSubscription } from 'applicaiton/store/reducers/Professionals/models';
import OnlineHours from 'common/components/OnlineHours';
import { ClinicProfessionalSubscriptionType } from 'common/types/subscriptions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (data: CreateSubscription) => void;
  data?: CreateSubscription;
}
const AddSubscriptionModal: FC<Props> = ({ onClose, onAdd, isOpen, data }) => {
  const { t } = useTranslation();

  const [weekDuration, setWeekDuration] = useState<Option>(
    getWeekDurationOptions[1],
  );
  const [monthDuration, setMonthDuration] = useState<Option>(
    getMonthDurationOptions[0],
  );
  const [isCompanySubscription, setIsCompanySubscription] =
    useState<boolean>(false);

  const { clinics } = useAppSelector((state) => state.clinics);
  const { isLoading, profileChanges } = useAppSelector(
    (state) => state.createProfessionalProfile,
  );

  const {
    setValue,
    errors,
    getValues,
    watch,
    handleSubmit,
    setError,
    register,
    clearErrors,
  } = useSubscriptionsForm({
    isCompanySubscription,
  });

  useEffect(() => {
    if (data) {
      setValue('clinicId', String(data.clinicId));
      setValue('title', data.title);
      setValue('price', String(data.price));
      setValue('durationInWeeks', data.durationInWeeks);
      setValue('durationInMonth', data.durationInWeeks / 4);
      setValue('teleconsultationMinutes', data.teleconsultationMinutes);
      setValue('hours', data?.onlineHours || []);
      setValue('type', data.type || ClinicProfessionalSubscriptionType.PUBLIC);
      setValue('minNumberOfEmployees', data.minNumberOfEmployees || 0);
      setValue('maxNumberOfEmployees', data.maxNumberOfEmployees || 0);

      setIsCompanySubscription(
        data.type === ClinicProfessionalSubscriptionType.COMPANY,
      );
    }
  }, [data]);

  const clinicsOptions = useMemo(() => {
    const options = profileChanges?.clinicsRelations?.map((item) => {
      const currentClinic = clinics.find(
        (clinic) => clinic.id === item.clinicId,
      );

      return {
        value: item.clinicId!,
        label: currentClinic?.name as string,
      };
    });
    return options || [];
  }, [profileChanges]);

  const handleCloseModal = () => {
    onClose();
  };

  const handleSelectClinic = (clinicId: string) => {
    setValue('clinicId', clinicId, { shouldValidate: true });
  };

  const handleChangePrice = (price: string) => {
    const isIntNumber = price.indexOf('.') < 0;
    const isValidDecimalNumber = price.slice(price.indexOf('.')).length < 4;
    if (isIntNumber || isValidDecimalNumber) {
      setValue('price', price, {
        shouldValidate: !!Object.keys(errors).length,
      });
    } else {
      setError('price', { message: t('appointment_types.min_price') || '' });
    }
  };

  const onSubmit = async () => {
    const {
      clinicId,
      title,
      price,
      durationInWeeks,
      teleconsultationMinutes,
      durationInMonth,
      hours,
      type,
      minNumberOfEmployees,
      maxNumberOfEmployees,
    } = getValues();

    const duration =
      Number(weekDuration?.value || 0) > 0
        ? Number(durationInWeeks)
        : Number(durationInMonth) * 4;
    const filteredHours = hours.filter((item) =>
      item.slots?.some(
        (slot) => slot.startTime?.length || slot.endTime?.length,
      ),
    );

    onAdd({
      id: data?.id ? data?.id : uuid(),
      clinicId: clinicId!,
      title: title!,
      price: Number(price)!,
      durationInWeeks: duration!,
      teleconsultationMinutes: teleconsultationMinutes!,
      onlineHours: filteredHours,
      type: type || ClinicProfessionalSubscriptionType.PUBLIC,
      ...(isCompanySubscription
        ? {
            minNumberOfEmployees,
            maxNumberOfEmployees,
          }
        : {}),
    });
  };

  const handleDurationInWeeksChanged = (value: Option) => {
    if (Number(value.value) > 0) {
      setMonthDuration(getMonthDurationOptions[0]);
      setWeekDuration(value);
      setValue('durationInWeeks', value.value as number, {
        shouldValidate: !!errors.durationInWeeks,
      });
    } else {
      if (Number(monthDuration.value) === 0) {
        setError('durationInWeeks', {
          message: t('professional_profile.subscriptions.min_duration') || '',
        });
      }
    }
  };

  const handleDurationInMonthChanged = (value: Option) => {
    if (Number(value.value) > 0) {
      setMonthDuration(value);
      setWeekDuration(getWeekDurationOptions[0]);
      setValue('durationInMonth', value.value as number, {
        shouldValidate: !!errors.durationInMonth,
      });
    } else {
      if (Number(weekDuration.value) === 0) {
        setError('durationInMonth', {
          message: t('professional_profile.subscriptions.min_duration') || '',
        });
      }
    }
  };

  const handleChangeSubscriptionType = (
    type: ClinicProfessionalSubscriptionType,
  ) => {
    setValue('type', type, { shouldValidate: !!errors.type });
    setIsCompanySubscription(
      type === ClinicProfessionalSubscriptionType.COMPANY,
    );
  };

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <Title>
          {t('professional_profile.subscriptions.modal_title')}
          <StyledCloseIcon onClick={handleCloseModal} />
        </Title>
        <FormsWrapper>
          <SubscriptionInfo>
            <InfoStyled>
              <p>{t('professional_profile.subscriptions.info_message')}</p>
            </InfoStyled>
            <SelectTypeStyled>
              <h2>
                {t(
                  'professional_profile.subscriptions.select_type_subscription',
                )}
              </h2>
              <div>
                <RadioButton
                  id="public-subscription"
                  label={
                    t(
                      'professional_profile.subscriptions.public_subscription',
                    ) || ''
                  }
                  checked={
                    watch('type') === ClinicProfessionalSubscriptionType.PUBLIC
                  }
                  onChange={() => {
                    handleChangeSubscriptionType(
                      ClinicProfessionalSubscriptionType.PUBLIC,
                    );
                  }}
                />
                <RadioButton
                  id="company-subscription"
                  label={
                    t(
                      'professional_profile.subscriptions.company_subscription',
                    ) || ''
                  }
                  checked={
                    watch('type') === ClinicProfessionalSubscriptionType.COMPANY
                  }
                  onChange={() => {
                    handleChangeSubscriptionType(
                      ClinicProfessionalSubscriptionType.COMPANY,
                    );
                  }}
                />
              </div>
            </SelectTypeStyled>
            <Input
              label={t('professional_profile.subscriptions.title') || ''}
              id={'title'}
              type="text"
              register={register}
              errorMessage={errors?.title?.message}
              placeholder={
                t('professional_profile.subscriptions.title_label') || ''
              }
            />
            <Dropdown
              id={'clinicId'}
              label={t('professional_profile.subscriptions.clinic_label') || ''}
              placeholder={
                t('professional_profile.subscriptions.clinic_placeholder') || ''
              }
              options={clinicsOptions!}
              value={watch('clinicId') || ''}
              onChange={(value) =>
                !Array.isArray(value) && handleSelectClinic(String(value.value))
              }
              errorMessage={errors.clinicId?.message}
            />
            {isCompanySubscription && (
              <Row>
                <Input
                  id={'minNumberOfEmployees'}
                  type={'number'}
                  label={
                    t(
                      'professional_profile.subscriptions.minimum_number_employees',
                    ) || ''
                  }
                  placeholder={
                    t('professional_profile.subscriptions.ex_1') || ''
                  }
                  min={'1'}
                  step={'1'}
                  value={String(watch('minNumberOfEmployees'))}
                  onChange={(e) => {
                    setValue('minNumberOfEmployees', Number(e.target.value), {
                      shouldValidate: !!errors?.minNumberOfEmployees,
                    });
                  }}
                  errorMessage={errors?.minNumberOfEmployees?.message}
                />
                <Input
                  id={'maxNumberOfEmployees'}
                  type={'number'}
                  label={
                    t(
                      'professional_profile.subscriptions.maximum_number_employees',
                    ) || ''
                  }
                  placeholder={
                    t('professional_profile.subscriptions.ex_10') || ''
                  }
                  min={String(watch('minNumberOfEmployees') + 1)}
                  step={'1'}
                  value={String(watch('maxNumberOfEmployees'))}
                  onChange={(e) =>
                    setValue('maxNumberOfEmployees', Number(e.target.value), {
                      shouldValidate: !!errors?.maxNumberOfEmployees,
                    })
                  }
                  errorMessage={errors?.maxNumberOfEmployees?.message}
                />
              </Row>
            )}
            <Row>
              <Input
                id={'price'}
                type={'number'}
                label={`${t('price') || ''}, €`}
                min={'0.00'}
                step={'0.01'}
                value={watch('price')}
                onChange={(e) => handleChangePrice(e.target.value)}
                errorMessage={errors?.price?.message}
              />
              <Input
                id={'teleconsultationMinutes'}
                type={'number'}
                label={`${t(
                  'professional_profile.subscriptions.teleconsultation',
                )!}, ${t('mins')!}`}
                placeholder={t('appointment_types.duration')!}
                min={'0'}
                step={'1'}
                value={String(watch('teleconsultationMinutes'))}
                onChange={(e) => {
                  setValue('teleconsultationMinutes', Number(e.target.value));
                }}
                errorMessage={errors?.teleconsultationMinutes?.message}
              />
            </Row>
            <Row>
              <Dropdown
                id={'durationInWeeks'}
                value={String(weekDuration.value)}
                label={`${t(
                  'professional_profile.subscriptions.week_duration',
                )!}, ${t('professional_profile.subscriptions.weeks')}`}
                placeholder={t('appointment_types.duration')! || ''}
                onChange={(value) => {
                  if (!Array.isArray(value)) {
                    handleDurationInWeeksChanged(value);
                  }
                }}
                options={getWeekDurationOptions}
                errorMessage={errors?.durationInWeeks?.message}
              />
              <Dropdown
                id={'durationInMonth'}
                value={String(monthDuration.value)}
                label={`${t(
                  'professional_profile.subscriptions.month_duration',
                )!}, ${t('professional_profile.subscriptions.months')!}`}
                placeholder={t('appointment_types.duration')!}
                onChange={(value) => {
                  if (!Array.isArray(value)) {
                    handleDurationInMonthChanged(value);
                  }
                }}
                options={getMonthDurationOptions}
                errorMessage={errors?.durationInMonth?.message}
              />
            </Row>
          </SubscriptionInfo>
          <OnlineHours
            clearErrors={clearErrors}
            setValue={setValue}
            enabledWorkingHours={data?.onlineHours!}
            hasError={!!errors.hours}
          />
        </FormsWrapper>

        <ButtonWrapper>
          <SecondaryButton onClick={handleCloseModal}>
            {t('cancel')}
          </SecondaryButton>
          <PrimaryButton
            type="button"
            onClick={handleSubmit(onSubmit)}
            disabled={!!Object.keys(errors).length || isLoading}
          >
            {t('save')}
          </PrimaryButton>
        </ButtonWrapper>
      </StyledModal>
    </Modal>
  );
};

export default AddSubscriptionModal;

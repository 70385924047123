import React, { FC, useEffect, useMemo } from 'react';
import { OpeningHours } from '@docbay/schemas';
import {
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TimeRange } from 'common/components';
import { TimeSlots } from 'common/types/clinics';
import { getSchedule } from 'common/helpers/getSchedule';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';

import { PrivateDoctorData } from 'features/feature-clinic-owner-profile/hooks/usePrivateDoctorForm';

import {
  SlotsWrapper,
  Wrapper,
  ReplicateButton,
  SlotsContainer,
} from './styles';

interface Props {
  watch: UseFormWatch<PrivateDoctorData>;
  clearErrors: UseFormClearErrors<PrivateDoctorData>;
  setValue: UseFormSetValue<PrivateDoctorData>;
}

const defaultSchedule = getSchedule({});

const Schedule: FC<Props> = ({ watch, setValue, clearErrors }) => {
  const { t } = useTranslation();

  const clinicScheduleHours =
    watch('professionalData.clinicsRelation.schedule.hours') || [];

  const openingHours = useMemo(() => {
    if (clinicScheduleHours.length) {
      const result = defaultSchedule.map((day) => {
        return {
          ...day,
          isEnabled: false,
          ...(clinicScheduleHours.find((day2) => day2.day === day.day) || {}),
        };
      });
      return result;
    } else {
      return defaultSchedule;
    }
  }, [clinicScheduleHours, defaultSchedule]);

  const handleAddSlot = (day: string) => {
    const newSlot = {
      startTime: '',
      endTime: '',
    };

    setValue('professionalData.clinicsRelation.schedule.hours', [
      ...openingHours.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            slots: [...item.slots, newSlot],
          };
        }
        return item;
      }),
    ]);
  };

  const handleEditSlot = (day: string, index: number, value: TimeSlots) => {
    const openingHoursEdited = openingHours?.map((item) => {
      if (item.day === day) {
        return {
          ...item,
          slots: item.slots.map((slot, i) => {
            if (index === i) {
              return { ...slot, ...value };
            }
            return slot;
          }),
        };
      }
      return item;
    });

    setValue(
      'professionalData.clinicsRelation.schedule.hours',
      openingHoursEdited,
    );
  };

  const handleEnableSlot = (day: string, value: boolean) => {
    setValue(
      'professionalData.clinicsRelation.schedule.hours',
      openingHours.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            isEnabled: value,
          };
        }
        return item;
      }),
    );
  };

  const handleRemoveSlot = (day: string, index: number) => {
    setValue(
      'professionalData.clinicsRelation.schedule.hours',
      openingHours.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            slots: item.slots.filter((slot, i) => index !== i),
          };
        }
        return item;
      }),
    );
  };

  const isStateChanged = useMemo(() => {
    const hasChanges = compareObjectsChanges(
      { openingHours },
      {
        openingHours: clinicScheduleHours,
      },
    );
    return hasChanges;
  }, [openingHours, clinicScheduleHours]);

  const scheduleValidation = (hours: OpeningHours[]) => {
    let isValid: boolean = true;

    hours.map((item) => {
      if (item.isEnabled) {
        item.slots.some((slot) => {
          if (!slot.endTime?.length || !slot.startTime?.length) {
            isValid = false;
          }
        });
      }
    });

    return isValid;
  };

  const handleScheduleChange = (hours: OpeningHours[]) => {
    let isValid: boolean = scheduleValidation(hours);

    if (isValid) {
      setValue('professionalData.clinicsRelation.schedule.hours', hours);
      clearErrors('professionalData.clinicsRelation.schedule.hours');
    }
  };

  useEffect(() => {
    if (openingHours.length && isStateChanged) {
      handleScheduleChange(openingHours);
    }
  }, [openingHours]);

  const handleAllDays = () => {
    const firstDay = openingHours.find((item) => item.day === 'Monday')!;

    const currentOpeningHours = openingHours.map((day) => {
      if (day.day === 'Saturday' || day.day === 'Sunday') {
        return day;
      }

      return {
        day: day.day,
        isEnabled: firstDay.isEnabled!,
        slots: firstDay.slots!,
      };
    });

    setValue(
      'professionalData.clinicsRelation.schedule.hours',
      currentOpeningHours,
    );
    clearErrors('professionalData.clinicsRelation.schedule.hours');
  };

  const isDisabledButton = useMemo(() => {
    if (!openingHours.length) return true;

    const firstDay = openingHours.find((item) => item.day === 'Monday');

    return !firstDay?.slots[0]?.startTime || !firstDay?.slots[0]?.endTime;
  }, [openingHours]);

  return (
    <Wrapper>
      <SlotsContainer>
        <SlotsWrapper>
          {openingHours.map((item, index) => (
            <TimeRange
              key={index}
              day={item.day}
              slots={item.slots}
              isEnabled={item.isEnabled}
              handleAddSlot={handleAddSlot}
              handleEditSlot={handleEditSlot}
              handleRemoveSlot={handleRemoveSlot}
              handleEnableSlot={handleEnableSlot}
            />
          ))}
        </SlotsWrapper>
        <div>
          <ReplicateButton disabled={isDisabledButton} onClick={handleAllDays}>
            {t('clinicsConfiguration.clinics.replicate')}
          </ReplicateButton>
        </div>
      </SlotsContainer>
    </Wrapper>
  );
};

export default Schedule;

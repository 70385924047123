import React, { lazy, Suspense } from 'react';
import i18n from 'i18n';
import { Loader } from 'common/loader/Loader';
import { WithHeader } from 'common/layout/WithHeader';
import { PathNames } from './constants';
import EditProfileSettings from '../pages/EditProfileSettings';
import ProfessionalEditPage from 'applicaiton/pages/ProfessionalEditPage';
import ProfessionalAddPage from 'applicaiton/pages/ProfessionalAddPage';
import HomePage from '../pages/HomePage';
import AppointmentTypeAddPage from 'applicaiton/pages/AppointmentTypeAddPage';
import AppointmentTypeEditPage from 'applicaiton/pages/AppointmentTypeEditPage';
import ProfilePage from 'applicaiton/pages/ProfilePage';
import UsersPage from 'applicaiton/pages/UsersPage';
import SecretaryAddPage from 'applicaiton/pages/SecretaryAddPage';
import UserAddPage from 'applicaiton/pages/UserAddPage';
import AdminEditPage from 'applicaiton/pages/AdminEditPage';
import SecretaryEditPage from 'applicaiton/pages/SecretaryEditPage';
import PatientPage from 'applicaiton/pages/PatientPage';
import UserPermissionsPage from 'applicaiton/pages/UserPermissionsPage';
import MobilePage from 'applicaiton/pages/MobilePage';
import {
  isShowClinicsForProfessional,
  isUserCanEditClinic,
} from 'common/helpers/permissions';
import ErrorPage from 'applicaiton/pages/ErrorPage/ErrorPage';
import {
  getIsUserPrivateDoctor,
  getUserId,
  getUserRole,
} from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import TeleconsultationPage from 'applicaiton/pages/TeleconsultationPage';
import ClinicBillingPage from 'applicaiton/pages/ClinicBillingPage';
import { Navigate } from 'react-router-dom';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import LoginAsPage from 'applicaiton/pages/LoginAsPage';
import WaitingListPage from 'applicaiton/pages/WaitingListPage';
import RequestListPage from 'applicaiton/pages/RequestListPage';
import MySubscribersPage from 'applicaiton/pages/MySubscribersPage';
import ChatsPage from 'applicaiton/pages/ChatsPage';
import PatientDataPage from 'applicaiton/pages/PatientDataPage';
import { featuresPermissions } from 'common/helpers/featuresPermissions';
import {
  AssignmentIcon,
  BadgeMenuIcon,
  BellHighIcon,
  BellMenuIcon,
  CalendarIcon,
  CommentIcon,
  DocBoxIcon,
  HomeIcon,
  ListAltIcon,
  PersonIcon,
  PollIcon,
  SettingsDarkIcon,
  SettingsIcon,
  SubscriptionsIcon,
  SubscriptionsMenuIcon,
} from 'applicaiton/assets';

const AuthPage = lazy(() => import('../pages/AuthPage'));
const ClinicRegistrationPage = lazy(() =>
  import('applicaiton/pages/ClinicRegistrationPage'),
);
const ClinicsPage = lazy(() => import('applicaiton/pages/ClinicsPage'));
const ProfessionalsPage = lazy(() =>
  import('applicaiton/pages/ProfessionalsPage'),
);
const SchedulerPage = lazy(() => import('applicaiton/pages/SchedulerPage'));
const ProfessionalSchedulePage = lazy(() =>
  import('applicaiton/pages/ProfessionalSchedulePage'),
);
const AppointmentTypesPage = lazy(() =>
  import('applicaiton/pages/AppointmentTypesPage'),
);
const TopicsOfChatsPage = lazy(() =>
  import('applicaiton/pages/TopicsOfChatsPage'),
);
const SubscriptionSettingsPage = lazy(() =>
  import('applicaiton/pages/SubscriptionSettingsPage'),
);
const MyPatientsPage = lazy(() => import('applicaiton/pages/MyPatientsPage'));
const SubscriptionsStatisticsPage = lazy(() =>
  import('applicaiton/pages/SubscriptionsStatisticsPage'),
);
const NotificationsPage = lazy(() =>
  import('applicaiton/pages/NotificationsPage'),
);
const NotificationsSettingsPage = lazy(() =>
  import('applicaiton/pages/NotificationsSettingsPage'),
);
const ClinicOwnerProfessionalSubscriptionsPage = lazy(() =>
  import('applicaiton/pages/ClinicOwnerProfessionalSubscriptionsPage'),
);

export const publicRouts = [
  {
    path: PathNames.login,
    title: 'Login',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.finishRegistration,
    title: 'Finish registration',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.expiredVerificationCode,
    title: 'Expired verification code',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.forgotPassword,
    title: 'Forgot Password',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.confirmChange,
    title: 'Confirm Change',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.createNewPassword,
    title: 'Create New Password',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.clinicRegistration,
    title: 'Clinic Registration',
    component: (
      <Suspense fallback={<Loader />}>
        <ClinicRegistrationPage />
      </Suspense>
    ),
  },
  {
    path: `${PathNames.loginAs}/:email/:token/:refreshToken/:expiresAt/:data`,
    title: 'Clinic Registration',
    component: (
      <Suspense fallback={<Loader />}>
        <LoginAsPage />
      </Suspense>
    ),
  },
];

export const getCurrentRoutes = () => {
  const {
    hasDocboxChatsPermission,
    hasDocboxSubscriptionConfigurationPermission,
    hasDocboxSubscribesPermission,
    hasAgendaPermission,
    hasAppointmentTypeConfigurationPermission,
    hasStaffManagementPermission,
    hasTeleconsultationPermission,
    hasDoctorManagementPermission,
    hasWaitingListPermission,
    hasPatientClinicalFilePermission,
    hasAppointmentRequestPermission,
  } = featuresPermissions();
  const showClinicsForProfessional = isShowClinicsForProfessional();
  const isCanEditClinics = isUserCanEditClinic();
  const isCanManageProfessional =
    userHasPermission(UserPermissions.clinicProfessionalsManagement) &&
    (hasDoctorManagementPermission || hasStaffManagementPermission);
  const isCanManageAppointmentTypes = userHasPermission(
    UserPermissions.clinicAppointmentTypesConfiguration,
  );
  const doShowAgenda = userHasPermission(UserPermissions.accessToOtherAgendas);
  const userRole = getUserRole();
  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);
  const isShowSubscriptions = userRole?.includes(UserRoles.professional);
  const isProfessionalRole = userRole === UserRoles.professional;
  const showAgendaOfOthers =
    (doShowAgenda && !isProfessionalRole) || isProfessionalRole;
  const isPrivateDoctor = getIsUserPrivateDoctor();

  const patientsRoutesList = [
    ...(isClinicOwnerRole
      ? [
          {
            path: `${PathNames.myPatients}`,
            title: 'My Patient',
            component: (
              <Suspense fallback={<Loader />}>
                <MyPatientsPage />
              </Suspense>
            ),
          },
        ]
      : []),
    ...(hasWaitingListPermission
      ? [
          {
            path: `${PathNames.waitingList}`,
            title: 'Waiting list',
            component: (
              <Suspense fallback={<Loader />}>
                <WaitingListPage />
              </Suspense>
            ),
          },
        ]
      : []),
    ...(hasAppointmentRequestPermission
      ? [
          {
            path: `${PathNames.requestList}`,
            title: 'Request list',
            component: <RequestListPage />,
          },
        ]
      : []),
  ];

  const settingsRoutesList = [
    ...(showClinicsForProfessional || isClinicOwnerRole
      ? [
          {
            path: PathNames.myClinics,
            title: 'Clinics',
            component: (
              <Suspense fallback={<Loader />}>
                <ClinicsPage />
              </Suspense>
            ),
            isSecondMenuItem: true,
            name: i18n.t('clinicsConfiguration.aside_menu.my_clinics'),
            image: HomeIcon,
          },
        ]
      : []),
    ...(isCanManageAppointmentTypes && hasAppointmentTypeConfigurationPermission
      ? [
          {
            path: PathNames.typesAppointments,
            title: 'Appointments Types',
            component: (
              <Suspense fallback={<Loader />}>
                <AppointmentTypesPage />
              </Suspense>
            ),
            isSecondMenuItem: true,
            name: i18n.t('clinicsConfiguration.aside_menu.types_appointments'),
            image: AssignmentIcon,
          },
          {
            path: `${PathNames.addAppointmentType}`,
            title: 'Add new Appointment Type',
            component: (
              <Suspense fallback={<Loader />}>
                <AppointmentTypeAddPage />
              </Suspense>
            ),
          },
          {
            path: `${PathNames.editAppointmentType}/:id`,
            title: 'Edit Appointment Type',
            component: (
              <Suspense fallback={<Loader />}>
                <AppointmentTypeEditPage />
              </Suspense>
            ),
          },
        ]
      : []),
    ...(isClinicOwnerRole
      ? [
          {
            path: PathNames.userPermissions,
            title: 'User Permissions',
            component: <UserPermissionsPage />,
            isSecondMenuItem: true,
            name: i18n.t('clinicsConfiguration.aside_menu.user_permissions'),
            image: PersonIcon,
          },
        ]
      : []),
    ...(!isProfessionalRole
      ? [
          {
            path: PathNames.notificationsSettings,
            title: 'Notifications Settings',
            component: (
              <Suspense fallback={<Loader />}>
                <NotificationsSettingsPage />
              </Suspense>
            ),
            isSecondMenuItem: true,
            name: i18n.t(
              'clinicsConfiguration.aside_menu.notifications_settings',
            ),
            image: BellHighIcon,
          },
        ]
      : []),
    ...(isClinicOwnerRole && hasTeleconsultationPermission
      ? [
          {
            path: PathNames.clinicBilling,
            title: 'Clinic Billing',
            component: (
              <Suspense fallback={<Loader />}>
                <ClinicBillingPage />
              </Suspense>
            ),
            isSecondMenuItem: true,
            name: i18n.t('clinicsConfiguration.aside_menu.clinic_billing'),
            image: SubscriptionsIcon,
          },
        ]
      : []),
    ...(isClinicOwnerRole
      ? [
          {
            path: `${PathNames.createClinic}`,
            title: 'Create clinic',
            component: (
              <Suspense fallback={<Loader />}>
                <EditProfileSettings />
              </Suspense>
            ),
          },
        ]
      : []),
    ...(isCanEditClinics || isClinicOwnerRole
      ? [
          {
            path: `${PathNames.editClinic}/:id`,
            title: 'Clinic profile',
            component: (
              <Suspense fallback={<Loader />}>
                <EditProfileSettings />
              </Suspense>
            ),
          },
        ]
      : []),
  ];

  const subscriptionsRoutesList = [
    ...(isShowSubscriptions
      ? [
          ...(hasDocboxSubscribesPermission
            ? [
                {
                  path: `${PathNames.mySubscribers}`,
                  title: 'My Subscribers',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <MySubscribersPage />
                    </Suspense>
                  ),
                  isSecondMenuItem: true,
                  name: i18n.t(
                    'clinicsConfiguration.aside_menu.my_subscribers',
                  ),
                  image: SubscriptionsIcon,
                },
              ]
            : []),
          {
            path: `${PathNames.topicsOfChats}`,
            title: 'Topics',
            component: (
              <Suspense fallback={<Loader />}>
                <TopicsOfChatsPage />
              </Suspense>
            ),
            isSecondMenuItem: true,
            name: i18n.t('clinicsConfiguration.aside_menu.topics_of_chats'),
            image: CommentIcon,
          },
          ...(hasDocboxSubscriptionConfigurationPermission
            ? [
                {
                  path: `${PathNames.subscriptionSettings}`,
                  title: 'Subscription Settings',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <SubscriptionSettingsPage />
                    </Suspense>
                  ),
                  isSecondMenuItem: true,
                  name: i18n.t(
                    'clinicsConfiguration.aside_menu.subscription_settings',
                  ),
                  image: SettingsDarkIcon,
                },
              ]
            : []),
          {
            path: `${PathNames.subscriptionsStatistics}`,
            title: 'Subscription Statistics',
            component: (
              <Suspense fallback={<Loader />}>
                <SubscriptionsStatisticsPage />
              </Suspense>
            ),
            isSecondMenuItem: true,
            name: i18n.t(
              'clinicsConfiguration.aside_menu.subscriptions_statistics',
            ),
            image: PollIcon,
          },
          ...(isClinicOwnerRole
            ? [
                {
                  path: `${PathNames.clinicOwnerProfessionalSubscriptions}`,
                  title: 'Subscription Statistics',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <ClinicOwnerProfessionalSubscriptionsPage />
                    </Suspense>
                  ),
                  isSecondMenuItem: true,
                  name: i18n.t(
                    'professional_profile.subscriptions.subscription_title',
                  ),
                  image: ListAltIcon,
                },
              ]
            : []),
        ]
      : []),
  ];

  const staffRoutesList = [
    ...(isCanManageProfessional
      ? [
          ...(hasDoctorManagementPermission
            ? [
                {
                  path: PathNames.professionals,
                  title: 'Professionals',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <ProfessionalsPage />
                    </Suspense>
                  ),
                },
                {
                  path: `${PathNames.editProfessional}/:id`,
                  title: 'Edit Professional Info',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <ProfessionalEditPage />
                    </Suspense>
                  ),
                },
                {
                  path: `${PathNames.addProfessional}`,
                  title: 'Add new Professional',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <ProfessionalAddPage />
                    </Suspense>
                  ),
                },
              ]
            : []),
          ...(hasStaffManagementPermission
            ? [
                {
                  path: `${PathNames.myUsers}`,
                  title: 'My Users',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <UsersPage />
                    </Suspense>
                  ),
                },
                {
                  path: `${PathNames.editAdmin}/:id`,
                  title: 'Edit admin',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <AdminEditPage />
                    </Suspense>
                  ),
                },
                {
                  path: `${PathNames.editSecretary}/:id`,
                  title: 'Edit secretary',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <SecretaryEditPage />
                    </Suspense>
                  ),
                },
                {
                  path: `${PathNames.addSecretary}`,
                  title: 'Add new secretary',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <SecretaryAddPage />
                    </Suspense>
                  ),
                },
                {
                  path: `${PathNames.addAdmin}`,
                  title: 'Add new user',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <UserAddPage />
                    </Suspense>
                  ),
                },
              ]
            : []),
        ]
      : []),
  ];

  const privateRoutes = [
    ...(showAgendaOfOthers && !isPrivateDoctor && hasAgendaPermission
      ? [
          {
            path: PathNames.schedule,
            title: 'Schedule',
            component: (
              <Suspense fallback={<Loader />}>
                <SchedulerPage />
              </Suspense>
            ),
            menuItem: true,
            name: i18n.t('clinicsConfiguration.aside_menu.clinics_agendas'),
            image: CalendarIcon,
          },
        ]
      : []),
    ...(isClinicOwnerRole && isPrivateDoctor && hasAgendaPermission
      ? [
          {
            path: PathNames.clinicOwnerSchedule,
            title: 'Clinic owner schedule',
            component: (
              <Suspense fallback={<Loader />}>
                <ProfessionalSchedulePage />
              </Suspense>
            ),
            menuItem: true,
            name: i18n.t('clinicsConfiguration.aside_menu.clinics_agendas'),
            image: CalendarIcon,
          },
        ]
      : []),
    ...(patientsRoutesList.length
      ? [
          {
            path: patientsRoutesList[0].path,
            title: patientsRoutesList[0].title,
            component: patientsRoutesList[0].component,
            menuItem: true,
            image: PersonIcon,
            name: i18n.t('clinicsConfiguration.aside_menu.my_patients'),
          },
          ...patientsRoutesList,
        ]
      : []),
    ...(isShowSubscriptions && hasDocboxChatsPermission
      ? [
          {
            path: `${PathNames.chats}`,
            title: 'Chats',
            component: (
              <Suspense fallback={<Loader />}>
                <ChatsPage />
              </Suspense>
            ),
            menuItem: true,
            image: DocBoxIcon,
            name: i18n.t('clinicsConfiguration.aside_menu.doc_box'),
          },
        ]
      : []),
    ...(subscriptionsRoutesList.length
      ? [
          {
            path: subscriptionsRoutesList[0].path,
            title: subscriptionsRoutesList[0].title,
            component: subscriptionsRoutesList[0].component,
            menuItem: true,
            name: i18n.t('clinicsConfiguration.aside_menu.subscriptions'),
            image: SubscriptionsMenuIcon,
          },
          ...subscriptionsRoutesList,
        ]
      : []),
    ...(staffRoutesList.length
      ? [
          {
            path: staffRoutesList[0].path,
            title: staffRoutesList[0].title,
            component: staffRoutesList[0].component,
            menuItem: true,
            name: i18n.t('clinicsConfiguration.aside_menu.staff'),
            image: BadgeMenuIcon,
          },
          ...staffRoutesList,
        ]
      : []),
    ...(isClinicOwnerRole || isProfessionalRole
      ? [
          {
            path: PathNames.notifications,
            title: 'Notifications',
            component: (
              <Suspense fallback={<Loader />}>
                <NotificationsPage />
              </Suspense>
            ),
            menuItem: true,
            name: i18n.t('clinicsConfiguration.aside_menu.notifications'),
            image: BellMenuIcon,
          },
        ]
      : []),
    ...(settingsRoutesList.length
      ? [
          {
            path: settingsRoutesList[0].path,
            title: settingsRoutesList[0].title,
            component: settingsRoutesList[0].component,
            menuItem: true,
            name: i18n.t('clinicsConfiguration.aside_menu.settings'),
            image: SettingsIcon,
          },
          ...settingsRoutesList,
        ]
      : []),
    {
      path: `${PathNames.profile}/:id`,
      title: '/profile',
      component: (
        <Suspense fallback={<Loader />}>
          <ProfilePage />
        </Suspense>
      ),
      menuItem: true,
    },
    {
      path: `${PathNames.teleconsultation}/:id`,
      title: 'Teleconsultation',
      component: <TeleconsultationPage />,
    },
    {
      path: `${PathNames.teleconsultation}`,
      title: 'Teleconsultation',
      component: <TeleconsultationPage />,
    },
    {
      path: `${PathNames.patient}/:id/:tab?`,
      title: '/patient',
      component: <PatientPage />,
    },
    ...(isShowSubscriptions && hasPatientClinicalFilePermission
      ? [
          {
            path: `${PathNames.consultationData}/:appointmentId`,
            title: '/consultation-data',
            component: <PatientDataPage />,
          },
        ]
      : []),
  ];
  const userId = getUserId();

  const homePagePath = showAgendaOfOthers
    ? privateRoutes[0].path
    : `${PathNames.profile}/${userId}`;

  const defaultRoutes = [
    {
      path: PathNames.home,
      title: '/',
      component: (
        <Suspense fallback={<Loader />}>
          <Navigate to={homePagePath} />
        </Suspense>
      ),
    },
    {
      path: PathNames.mobileInfo,
      title: 'Info',
      component: (
        <Suspense fallback={<Loader />}>
          <MobilePage />
        </Suspense>
      ),
    },
    {
      path: PathNames.support,
      title: 'Support',
      component: (
        <Suspense fallback={<Loader />}>
          <WithHeader>
            <HomePage />
          </WithHeader>
        </Suspense>
      ),
    },
    {
      path: '/*',
      title: 'Error',
      component: <ErrorPage />,
    },
  ];

  return [...privateRoutes, ...defaultRoutes];
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ViewBy } from 'features/feature-notifications-settings/constants';

import { FilterSectionProps } from './modules';
import { ButtonStyled, FilterSectionStyled } from './styles';

const TabsSection: FC<FilterSectionProps> = ({
  viewBy,
  setViewBy,
  showPatientTab,
  showDoctorTab,
  showAdminTab,
  showSecretaryTab,
}) => {
  const { t } = useTranslation();

  return (
    <FilterSectionStyled>
      {showPatientTab && (
        <ButtonStyled
          isActive={viewBy === ViewBy.Patients}
          onClick={() => {
            setViewBy(ViewBy.Patients);
          }}
        >
          {t('patients')}
        </ButtonStyled>
      )}
      {showDoctorTab && (
        <ButtonStyled
          isActive={viewBy === ViewBy.Doctor}
          onClick={() => {
            setViewBy(ViewBy.Doctor);
          }}
        >
          {t('doctor')}
        </ButtonStyled>
      )}
      {showAdminTab && (
        <ButtonStyled
          isActive={viewBy === ViewBy.Admin}
          onClick={() => {
            setViewBy(ViewBy.Admin);
          }}
        >
          {t('admin')}
        </ButtonStyled>
      )}
      {showSecretaryTab && (
        <ButtonStyled
          isActive={viewBy === ViewBy.Secretary}
          onClick={() => {
            setViewBy(ViewBy.Secretary);
          }}
        >
          {t('secretary')}
        </ButtonStyled>
      )}
    </FilterSectionStyled>
  );
};

export default TabsSection;

export enum PathNames {
  //schedule
  schedule = '/schedule',
  clinicOwnerSchedule = '/clinic-owner-schedule',
  //settings
  settings = '/settings',
  myClinics = '/settings/my-clinics',
  createClinic = '/settings/clinics/create',
  editClinic = '/settings/my-clinics/edit',
  typesAppointments = '/settings/types-of-appointments',
  addAppointmentType = '/settings/types-of-appointments/add',
  editAppointmentType = '/settings/types-of-appointments/edit',
  userPermissions = '/settings/user-permissions',
  notificationsSettings = '/settings/notifications-settings',
  clinicBilling = '/settings/clinic-billing',
  //staff
  professionals = '/staff/professionals',
  editProfessional = '/staff/professionals/edit',
  addProfessional = '/staff/professionals/add',
  myUsers = '/staff/my-users',
  editAdmin = '/staff/my-users/admin/edit',
  addAdmin = '/staff/my-users/admin/add',
  editSecretary = '/staff/my-users/secretary/edit',
  addSecretary = '/staff/my-users/secretary/add',
  //patients
  patients = '/patients',
  myPatients = '/patients/my-patients',
  waitingList = '/patients/waiting-list',
  requestList = '/patients/request-list',
  //subscriptions
  subscriptions = '/subscriptions',
  mySubscribers = '/subscriptions/my-subscribers',
  topicsOfChats = '/subscriptions/topics-of-chats',
  subscriptionSettings = '/subscriptions/subscription-settings',
  subscriptionsStatistics = '/subscriptions/subscriptions-statistics',
  clinicOwnerProfessionalSubscriptions = '/subscriptions/clinic-owner-professional-subscriptions',
  //other
  home = '/',
  login = '/login',
  finishRegistration = '/create-password',
  expiredVerificationCode = '/create-password/expired',
  clinicRegistration = '/clinic-registration',
  forgotPassword = '/forgot-password',
  confirmChange = '/confirm-password',
  createNewPassword = '/create-new-password',
  error = '/error',
  profile = '/profile',
  support = '/support',
  teleconsultation = '/teleconsultation',
  mobileInfo = '/mobile-info',
  loginAs = 'login-as',
  chats = '/chats',
  notifications = '/notifications',
  consultationData = '/consultation-data',
  patient = '/patient',
}

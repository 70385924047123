import React, { FC, useEffect, useMemo, useState } from 'react';
import { TimeRange } from 'common/components';
import { TimeSlots } from 'common/types/clinics';
import { getSchedule } from 'common/helpers/getSchedule';
import {
  SlotsWrapper,
  Wrapper,
  ReplicateButton,
  SlotsContainer,
  WorkingDays,
  WorkingHours,
  WorkingSchedule,
} from './styles';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { ClinicProfessionalUpdateDto, OpeningHours } from '@docbay/schemas';
import {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { useAppSelector } from 'common/hooks/redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface Props {
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
  setValue: UseFormSetValue<any>;
  arrayIndex: number;
  chosenClinicName?: string;
  clinicEnabledWorkingHours?: OpeningHours[];
}

const Schedule: FC<Props> = ({
  setValue,
  arrayIndex,
  clearErrors,
  chosenClinicName,
  clinicEnabledWorkingHours,
}) => {
  const { t } = useTranslation();
  const { profileChanges } = useAppSelector(
    (state) => state.createProfessionalProfile,
  );

  const allClinic = [...(profileChanges?.clinicsRelations || [])];
  const currentClinic: ClinicProfessionalUpdateDto = allClinic[arrayIndex - 1];
  const clinicScheduleHours = currentClinic.schedule?.hours || [];
  const [openingHours, setOpeningHours] = useState<OpeningHours[]>([]);

  useEffect(() => {
    if (!clinicScheduleHours?.length) {
      setOpeningHours(getSchedule({}));
    } else {
      const defaultSchedule = getSchedule({});

      const result = defaultSchedule.map((day) => {
        return {
          ...day,
          isEnabled: false,
          ...(clinicScheduleHours.find((day2) => day2.day === day.day) || {}),
        };
      });

      setOpeningHours(result);
    }
  }, [arrayIndex]);

  const handleAddSlot = (day: string) => {
    const newSlot = {
      startTime: '',
      endTime: '',
    };

    setOpeningHours((prev) => [
      ...prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            slots: [...item.slots, newSlot],
          };
        }
        return item;
      }),
    ]);
  };

  const handleEditSlot = (day: string, index: number, value: TimeSlots) => {
    const openingHoursEdited = openingHours?.map((item) => {
      if (item.day === day) {
        return {
          ...item,
          slots: item.slots.map((slot, i) => {
            if (index === i) {
              return { ...slot, ...value };
            }
            return slot;
          }),
        };
      }
      return item;
    });

    setOpeningHours(openingHoursEdited);
  };

  const handleEnableSlot = (day: string, value: boolean) => {
    setOpeningHours((prev) =>
      prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            isEnabled: value,
          };
        }
        return item;
      }),
    );
  };

  const handleRemoveSlot = (day: string, index: number) => {
    setOpeningHours((prev) =>
      prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            slots: item.slots.filter((slot, i) => index !== i),
          };
        }
        return item;
      }),
    );
  };

  const isStateChanged = useMemo(() => {
    const hasChanges = compareObjectsChanges(
      { openingHours },
      {
        openingHours: clinicScheduleHours,
      },
    );
    return hasChanges;
  }, [openingHours, clinicScheduleHours]);

  const scheduleValidation = (hours: OpeningHours[]) => {
    let isValid: boolean = true;

    hours.map((item) => {
      if (item.isEnabled) {
        item.slots.some((slot) => {
          if (!slot.endTime?.length || !slot.startTime?.length) {
            isValid = false;
          }
        });
      }
    });

    return isValid;
  };

  const handleScheduleChange = (hours: OpeningHours[]) => {
    let isValid: boolean = scheduleValidation(hours);

    const newArr = hours.filter((item) => item.isEnabled);

    if (isValid) {
      setValue('hours', newArr);
      clearErrors('hours');
    } else {
      setValue('hours', []);
    }
  };

  useEffect(() => {
    if (openingHours.length && isStateChanged) {
      handleScheduleChange(openingHours);
    }
  }, [openingHours]);

  const handleAllDays = () => {
    const firstDay = openingHours.find((item) => item.day === 'Monday')!;

    const currentOpeningHours = openingHours.map((day) => {
      if (day.day === 'Saturday' || day.day === 'Sunday') {
        return day;
      }

      return {
        day: day.day,
        isEnabled: firstDay.isEnabled!,
        slots: firstDay.slots!,
      };
    });

    setValue('hours', currentOpeningHours);
    clearErrors('hours');
    setOpeningHours(currentOpeningHours);
  };

  const isDisabledButton = useMemo(() => {
    if (!openingHours.length) return true;

    const firstDay = openingHours.find((item) => item.day === 'Monday');

    return !firstDay?.slots[0]?.startTime || !firstDay?.slots[0]?.endTime;
  }, [openingHours]);

  return (
    <Wrapper>
      <SlotsContainer>
        <SlotsWrapper>
          {openingHours.map((item, index) => (
            <TimeRange
              key={index}
              day={item.day}
              slots={item.slots}
              isEnabled={item.isEnabled}
              handleAddSlot={handleAddSlot}
              handleEditSlot={handleEditSlot}
              handleRemoveSlot={handleRemoveSlot}
              handleEnableSlot={handleEnableSlot}
            />
          ))}
        </SlotsWrapper>
        <div>
          <ReplicateButton disabled={isDisabledButton} onClick={handleAllDays}>
            {t('clinicsConfiguration.clinics.replicate')}
          </ReplicateButton>
          {!!chosenClinicName && !!clinicEnabledWorkingHours?.length && (
            <WorkingSchedule>
              <h2>
                {`${t(
                  'professional_profile.overview_of_place_opening_hours',
                )} ${chosenClinicName}:`}
              </h2>
              <WorkingDays>
                {clinicEnabledWorkingHours!.map((item) => (
                  <div key={item.day}>
                    <p>{item.day}</p>
                    <WorkingHours>
                      {item.slots.map((hour, index) => (
                        <p key={index}>
                          {dayjs(hour.startTime, 'HH:mm').format('HH:mm')} -{' '}
                          {dayjs(hour.endTime, 'HH:mm').format('HH:mm')}
                        </p>
                      ))}
                    </WorkingHours>
                  </div>
                ))}
              </WorkingDays>
            </WorkingSchedule>
          )}
        </div>
      </SlotsContainer>
    </Wrapper>
  );
};

export default Schedule;

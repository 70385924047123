import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '../ButtonGroup';
import { Checkbox, PrimaryButton, SecondaryButton } from 'common/components';
import { StyledArticle } from './styles';
import {
  currentClinicOwnerRegistration,
  setClinicOwnerRegistrationSessionStorage,
} from 'applicaiton/sessionStorage/clinicRegistration';

interface ContractualAgreementProps {
  setStep: (value: number) => void;
}

const ContractualAgreement: FC<ContractualAgreementProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const defaultState =
    !!currentClinicOwnerRegistration().contractualAgreement.isAgree;
  const [isTermsCheked, setIsTermsCheked] = useState(defaultState);

  const handleChangeTerms = () => {
    setIsTermsCheked((prev) => !prev);
    setClinicOwnerRegistrationSessionStorage('contractualAgreement', {
      isAgree: !isTermsCheked,
    });
  };

  return (
    <StyledArticle>
      <section>
        <article>
          <h2>{t('clinicRegistration.contractual_agreement.title')}</h2>
          <h3>{t('clinicRegistration.contractual_agreement.sub_title')}</h3>
          <div>{t('clinicRegistration.contractual_agreement.text_1_1')}</div>
          <div>{t('clinicRegistration.contractual_agreement.text_1_2_1')}</div>
          <div>{t('clinicRegistration.contractual_agreement.text_1_2_2')}</div>
        </article>
      </section>
      <div>
        <Checkbox
          id={'TermsConditions'}
          checked={isTermsCheked}
          onChange={handleChangeTerms}
          label={t('clinicRegistration.agree_terms_conditions') || ''}
        />
        <ButtonGroup>
          <PrimaryButton
            onClick={() => {
              setStep(2);
            }}
            disabled={!isTermsCheked}
          >
            {t('clinicRegistration.continue')}
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              setStep(0);
            }}
          >
            {t('clinicRegistration.back')}
          </SecondaryButton>
        </ButtonGroup>
      </div>
    </StyledArticle>
  );
};

export default ContractualAgreement;

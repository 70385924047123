interface GetScheduleProps {
  disableAll?: boolean;
}

export const getSchedule = ({ disableAll }: GetScheduleProps) => {
  //this array used as data for backend, don't translate this
  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const workingHours = daysOfWeek.map((day) => {
    const isSaturday = day === 'Saturday';
    const isSunday = day === 'Sunday';
    return {
      day,
      isEnabled: isSaturday || isSunday || disableAll ? false : true,
      slots: [
        {
          startTime: '',
          endTime: '',
        },
      ],
    };
  });

  return workingHours;
};

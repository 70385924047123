import React, { FC } from 'react';

import { DeleteIcon } from 'applicaiton/assets';

import { TableRowProps } from './models';
import { StyledTr, ActionsStyled } from './styles';

const TableRow: FC<TableRowProps> = ({ item, onDelete }) => {
  return (
    <StyledTr>
      <td>
        <span>
          {item.professional?.firstName} {item.professional?.lastName}
        </span>
      </td>
      <td>
        <span>
          {item.patient?.firstName} {item.patient?.lastName}
        </span>
      </td>
      <td className="phone-column">
        <span>{item.patient?.phone}</span>
      </td>
      <td>
        <ActionsStyled>
          <button
            type="button"
            onClick={() => {
              onDelete(item);
            }}
          >
            <DeleteIcon />
          </button>
        </ActionsStyled>
      </td>
    </StyledTr>
  );
};
export default TableRow;

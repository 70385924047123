export enum SupportedCountries {
  Portugal = 'PT',
  France = 'FR',
  Spain = 'ES',
  Ukraine = 'UA',
}

export interface SupportedCountriesDto {
  code: string;
  id: string;
  name: string;
}

import React, { FC } from 'react';
import { SideMenu, RequestList } from 'features';
import { Wrapper } from './styles';

const RequestListPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <RequestList />
    </Wrapper>
  );
};

export default RequestListPage;

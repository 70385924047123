export enum ViewBy {
  Agenda = 'Agenda',
  ClinicsConfiguration = 'ClinicsConfiguration',
}

export enum Permissions {
  ClinicOpeningHours = 'ClinicOpeningHours',
  ClinicFAQ = 'ClinicFAQ',
  ClinicTeleconsultationPayment = 'ClinicTeleconsultationPayment',
  ClinicDescription = 'ClinicDescription',
  ClinicProfessionalsManagement = 'ClinicProfessionalsManagement',
  ClinicAppointmentTypesConfiguration = 'ClinicAppointmentTypesConfiguration',
  ClinicDeletion = 'ClinicDeletion',
  ClinicCommunicationWithPatients = 'ClinicCommunicationWithPatients',
  ClinicInsurancesConfiguration = 'ClinicInsurancesConfiguration',
  CreateAppointment = 'CreateAppointment',
  RescheduleAppointment = 'RescheduleAppointment',
  CancelAgenda = 'CancelAgenda',
  CreateAbsence = 'CreateAbsence',
  ProfessionalOpeningHours = 'ProfessionalOpeningHours',
  AccessToOtherAgendas = 'AccessToOtherAgendas',
  AccessToAppointmentRequestList = 'AccessToAppointmentRequestList',
}

export enum UserType {
  Admin = 'Admin',
  Professional = 'Professional',
  Secretary = 'Secretary',
}

export enum ClinicsActions {
  fetchProfiles = 'fetchProfiles',
  deleteProfile = 'deleteProfile',
  getClinicProfile = 'getClinicProfile',
  saveClinicProfile = 'saveClinicProfile',
  createClinicProfile = 'createClinicProfile',
  fetchInsurances = 'fetchInsurances',
  searchProfessionalsByFilters = 'searchProfessionalsByFilters',
  getSpecializationsByClinic = 'getSpecializationsByClinic',
}

export enum PhotoActions {
  uploadPhoto = 'uploadPhoto',
}

export enum MapBox {
  fetchLocation = 'fetchLocation',
}

export enum ProfessionalsActions {
  fetchProfessionals = 'fetchProfessionals',
  fetchProfessionalsForAutosuggest = 'fetchProfessionalsForAutosuggest',
  fetchProfessionalById = 'fetchProfessionalById',
  deleteProfessional = 'deleteProfessional',
  createProfessional = 'createProfessional',
  updateProfessional = 'updateProfessional',
  updateProfessionalEmail = 'updateProfessionalEmail',
  resendAuthorizationData = 'resendAuthorizationData',
  checkProfessionalVerificationCode = 'checkProfessionalVerificationCode',
  createProfessionalPassword = 'createProfessionalPassword',
  createClinicOwnerProfessional = 'createClinicOwnerProfessional',
  professionalVerifyOldEmail = 'professionalVerifyOldEmail',
  professionalVerifyCode = 'professionalVerifyCode',
  professionalSetNewEmail = 'professionalSetNewEmail',
  restoreClinicOwnerProfessional = 'restoreClinicOwnerProfessional',
  professionalVerifyPhone = 'professionalVerifyPhone',
  professionalSetNewPhone = 'professionalSetNewPhone',
  fetchProfessionalsSubscribers = 'fetchProfessionalsSubscribers',
  fetchSubscriptions = 'fetchSubscriptions',
  fetchSubscriptionStatistic = 'fetchSubscriptionStatistic',
}

export enum SpecializationsActions {
  fetchSpecializations = 'fetchSpecializations',
}

export enum TeleconsultationActions {
  getConnectionData = 'getConnectionData',
  createChat = 'createChat',
  getConnectionDataByRoomId = 'getConnectionDataByRoomId',
}

export enum ClinicOwnerActions {
  signUpOwner = 'signUpOwner',
  signUpConfirmOwner = 'signUpConfirmOwner',
  updateClinicOwner = 'updateClinicOwner',
  searchClinicsInChain = 'searchClinicsInChain',
  getClinicOwnerById = 'getClinicOwnerById',
  resendConfirmationCode = 'resendConfirmationCode',
  clinicOwnerVerifyOldEmail = 'clinicOwnerVerifyOldEmail',
  clinicOwnerVerifyCode = 'clinicOwnerVerifyCode',
  clinicOwnerSetNewEmail = 'clinicOwnerSetNewEmail',
  getPermissionsByClinicOwnerId = 'getPermissionsByClinicOwnerId',
  updatePermissionsByClinicOwnerId = 'updatePermissionsByClinicOwnerId',
  fetchClinicOwnerStaff = 'fetchClinicOwnerStaff',
  applyClinicOwnerPromocode = 'applyClinicOwnerPromocode',
  clinicOwnerVerifyPhone = 'clinicOwnerVerifyPhone',
  clinicOwnerSetNewPhone = 'clinicOwnerSetNewPhone',
  getSubscriptions = 'getSubscriptions',
  getCustomerPortalLink = 'getCustomerPortalLink',
  getPhotoPackage = 'getPhotoPackage',
  getAccountStatus = 'getAccountStatus',
  getCustomerBillingLink = 'getCustomerBillingLink',
  getCustomerBillingUpdateLink = 'getCustomerBillingUpdateLink',
  getCustomerBillingActivationLink = 'getCustomerBillingActivationLink',
  deleteCOAccount = 'deleteCOAccount',
}

export enum StripeActions {
  createCustomer = 'createCustomer',
  sepaDebitSetup = 'sepaDebitSetup',
  createSubscription = 'createSubscription',
  getMandateInfo = 'getMandateInfo',
  payInvoice = 'payInvoice',
  fetchSubscriptions = 'fetchSubscriptions',
  clinicOwnerPayments = 'clinicOwnerPayments',
}

export enum AgendaActions {
  searchAgenda = 'searchAgenda',
  searchAgendaSlots = 'searchAgendaSlots',
  fetchOpenAgenda = 'fetchOpenAgenda',
  addOpenAgenda = 'addOpenAgenda',
  removeOpenAgenda = 'removeOpenAgenda',
  editOpenAgenda = 'editOpenAgenda',
}

export enum ConfusingAgendasActions {
  fetchConfusingAgenda = 'fetchConfusingAgenda',
}

export enum AbsenceActions {
  createAbsence = 'createAbsence',
  updateAbsence = 'updateAbsence',
  deleteAbsence = 'deleteAbsence',
  getAbsenceById = 'getAbsenceById',
}

export enum PatientsActions {
  fetchPatients = 'fetchPatients',
  fetchPatientsAndFamilyMembers = 'fetchPatientsAndFamilyMembers',
  createPatient = 'createPatient',
  getPatientById = 'getPatientById',
  updatePatients = 'updatePatients',
  deletePatient = 'deletePatient',
  getPatientsSubscriptions = 'getPatientsSubscriptions',
  getFamilyMembers = 'getFamilyMembers',
  createFamilyMember = 'createFamilyMember',
  deleteFamilyMember = 'deleteFamilyMember',
  updateFamilyMember = 'updateFamilyMember',
  uploadCSV = 'uploadCSV',
}

export enum AppointmentsActions {
  fetchPatientsAppointments = 'fetchPatientsAppointments',
  fetchAppointmentsWaitingList = 'fetchAppointmentsWaitingList',
  createAppointment = 'createAppointment',
  fetchAppointmentPatients = 'fetchAppointmentPatients',
  deleteAppointment = 'deleteAppointment',
  fetchAppointmentById = 'fetchAppointmentById',
  editAppointment = 'editAppointment',
  endAppointment = 'endAppointment',
  updateAppointmentStatus = 'updateAppointmentStatus',
  fetchAppointmentRequestList = 'fetchAppointmentRequestList',
  deleteAppointmentRequest = 'deleteAppointmentRequest',
}

export enum AppointmentTypesActions {
  fetchAppointmentTypes = 'fetchAppointmentTypes',
  postAppointmentType = 'postAppointmentType',
  fetchAppointmentTypeById = 'fetchAppointmentTypeById',
  updateAppointmentType = 'updateAppointmentType',
  deleteAppointmentType = 'deleteAppointmentType',
  updateAppointmentTypeNotifications = 'updateAppointmentTypeNotifications',
  changeAppointmentTypeNotificationsConfig = 'changeAppointmentTypeNotificationsConfig',
}
export enum UsersActions {
  usersLogin = 'usersLogin',
  usersRefreshToken = 'usersRefreshToken',
  usersForgotPassword = 'usersForgotPassword',
  usersVerificationCode = 'usersVerificationCode',
  usersCreateNewPassword = 'usersCreateNewPassword',
  userChangePassword = 'userChangePassword',
  signOut = 'signOut',
  checkUsersEmailExisting = 'checkUsersEmailExisting',
  setUserLanguage = 'setUserLanguage',
  getUserProfile = 'getUserProfile',
}

export enum AppointmentsDocumentsActions {
  postDocuments = 'postDocuments',
  downloadDocuments = 'downloadDocuments',
  deleteDocuments = 'deleteDocuments',
  editDocuments = 'editDocuments',
  postDocumentsToAppointment = 'postDocumentsToAppointment',
}

export enum AdminsActions {
  fetchAdmins = 'fetchAdmins',
  createAdmin = 'createAdmin',
  getAdminById = 'getAdminById',
  deleteAdmin = 'deleteAdmin',
  editAdmin = 'editAdmin',
  resendAuthorizationData = 'resendAuthorizationData',
  checkAdminVerificationCode = 'checkAdminVerificationCode',
  createAdminPassword = 'createAdminPassword',
  adminVerifyOldEmail = 'adminVerifyOldEmail',
  adminSetNewEmail = 'adminSetNewEmail',
  adminVerifyCode = 'adminVerifyCode',
  adminVerifyPhone = 'adminVerifyPhone',
  adminSetNewPhone = 'adminSetNewPhone',
}

export enum SecretariesActions {
  createSecretary = 'createSecretary',
  getSecretaryById = 'getSecretaryById',
  deleteSecretary = 'deleteSecretary',
  editSecretary = 'editSecretary',
  resendAuthorizationData = 'resendAuthorizationData',
  checkSecretariesVerificationCode = 'checkSecretariesVerificationCode',
  createSecretariesPassword = 'createSecretariesPassword',
  secretaryVerifyOldEmail = 'secretaryVerifyOldEmail',
  secretaryVerifyCode = 'secretaryVerifyCode',
  secretarySetNewEmail = 'secretarySetNewEmail',
  secretaryVerifyPhone = 'secretaryVerifyPhone',
  secretarySetNewPhone = 'secretarySetNewPhone',
}

export enum TopicsActions {
  getTopics = 'getTopics',
  createTopic = 'createTopic',
  editTopic = 'editTopic',
  deleteTopic = 'deleteTopic',
}

export enum ChatsActions {
  getChats = 'getTopics',
  getAccessToken = 'getAccessToken',
  createChat = 'createChat',
  updateChat = 'updateChat',
  archiveChat = 'archiveChat',
  deleteChat = 'deleteChat',
  unArchiveChat = 'unArchiveChat',
  postMassMessagesChat = 'postMassMessagesChat',
  deleteMassMessage = 'deleteMassMessage',
  startChatTeleconsultation = 'startChatTeleconsultation',
  addDocumentMessage = 'addDocumentMessage',
}
export enum OnlineHoursActions {
  fetchOnlineHours = 'fetchOnlineHours',
  createOnlineHours = 'createOnlineHours',
  updateOnlineHours = 'updateOnlineHours',
}
export enum ChatTeleconsultationActions {
  getChatAccessToken = 'getChatAccessToken',
  uploadChatTeleconsultationDocument = 'uploadChatTeleconsultationDocument',
  downloadChatTeleconsultationDocument = 'downloadChatTeleconsultationDocument',
  deleteChatDocumentMessage = 'deleteChatDocumentMessage',
}
export enum NotificationsInboxActions {
  getNotifications = 'getNotifications',
  updateNotification = 'updateNotification',
  readAllNotification = 'readAllNotification',
}
export enum NotificationsSettingsActions {
  fetchNotificationsSettings = 'fetchNotificationsSettings',
  updateNotificationsSettings = 'updateNotificationsSettings',
}
export enum ConsultationDataActions {
  getConsultationData = 'getConsultationData',
  saveConsultationData = 'saveConsultationData',
  getConsultationDataHistory = 'getConsultationDataHistory',
  getAllergies = 'getAllergies',
  getDiseases = 'getDiseases',
  getSymptoms = 'getSymptoms',
  getSurgeries = 'getSurgeries',
  setPatientAllergy = 'setPatientAllergy',
  setPatientDisease = 'setPatientDisease',
  removePatientAllergy = 'removePatientAllergy',
  removePatientDisease = 'removePatientDisease',
  getPatientMedicalBackground = 'getPatientMedicalBackground',
  setPatientFamilyHistoricData = 'setPatientFamilyHistoricData',
  updatePatientFamilyHistoricData = 'updatePatientFamilyHistoricData',
  setPatientSurgeryData = 'setPatientSurgeryData',
  updatePatientSurgeryData = 'updatePatientSurgeryData',
  deletePatientFamilyHistoricData = 'deletePatientFamilyHistoricData',
  deleteSurgeryData = 'deleteSurgeryData',
  getHealthMetricsBySpecialization = 'getHealthMetricsBySpecialization',
}
export enum CountriesActions {
  getSupportedCountries = 'getSupportedCountries',
}
export enum PrivateDoctorActions {
  getPrivateDoctor = 'getPrivateDoctor',
  updatePrivateDoctor = 'updatePrivateDoctor',
}
export enum TimeZoneActions {
  setTimeZone = 'setTimeZone',
}

import styled from 'styled-components';

interface ItemStyledProps {
  isStrokeSvg?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h2 {
    font-family: AcidGrotesk-Bold;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
`;

export const ItemsList = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px 8px;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  background: ${({ theme }) => theme.color.white};
`;

export const ItemStyled = styled.div<ItemStyledProps>`
  display: flex;
  align-items: center;
  min-width: 400px;
  max-width: 400px;

  span {
    color: ${({ theme }) => theme.color.grey500};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin: 0 6px 0 8px;
  }

  p {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    path {
      fill: ${({ theme, isStrokeSvg }) =>
        !isStrokeSvg ? theme.color.grey600 : ''};
      stroke: ${({ theme, isStrokeSvg }) =>
        isStrokeSvg ? theme.color.grey600 : ''};
    }
  }
`;

export const ItemTeleconsultationStyled = styled.div<ItemStyledProps>`
  display: flex;
  align-items: center;
  min-width: 400px;
  max-width: 400px;

  span {
    color: ${({ theme }) => theme.color.grey500};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin: 0 6px 0 8px;
  }

  p {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    min-width: 20px;
    height: 20px;
    path {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`;

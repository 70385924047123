import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createAppointment,
  fetchAppointmentPatients,
  deleteAppointment,
  fetchAppointmentById,
  editAppointment,
  fetchAppointmentsWaitingList,
  endAppointment,
  updateAppointmentStatus,
  fetchAppointmentRequestList,
  deleteAppointmentRequest,
} from './ActionCreators';
import { InitialAppointmentState, SlotsDetails } from './models';
import {
  AppointmentBriefResponseDto,
  AppointmentResponseDto,
  AppointmentUpdateResponseDto,
  PatientResponseDto,
  AppointmentWaitingListResponseDto,
  PatientSearchResponseDto,
} from '@docbay/schemas';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';

const initialState: InitialAppointmentState = {
  isLoading: false,
  error: '',
  total: 0,
  page: 0,
  totalPages: 0,
  appointmentPatients: null,
  currentPatient: null,
  currentFamilyMemberSearch: null,
  currentAppointment: null,
  isCreatedAppointment: false,
  isShowDeletedWindow: false,
  isDeleted: false,
  isEdited: false,
  appointmentToDeleted: null,
  deletedSuccess: false,
  bookedAgainAppointmentId: null,
  mode: null,
  professionalId: undefined,
  startDate: undefined,
  absenceId: undefined,
  appointmentId: undefined,
  isShowProfile: false,
  waitingList: null,
  waitingStartDate: undefined,
  isEndedAppointment: false,
  requestList: [],
};

const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    setCurrentPatient: (
      state,
      actions: PayloadAction<PatientResponseDto | null>,
    ) => {
      state.currentPatient = actions.payload;
    },
    setCurrentFamilyMemberSearch: (
      state,
      actions: PayloadAction<PatientSearchResponseDto | null>,
    ) => {
      state.currentFamilyMemberSearch = actions.payload;
    },
    setIsHiddenShowProfile: (state, actions) => {
      state.isShowProfile = actions.payload;
    },
    setPatients: (
      state,
      actions: PayloadAction<PatientSearchResponseDto[] | null>,
    ) => {
      state.appointmentPatients = actions.payload;
    },
    setIsCreatedAppointment: (state, actions: PayloadAction<boolean>) => {
      state.isCreatedAppointment = actions.payload;
    },
    setIsAppointmentDeleted: (state, actions: PayloadAction<boolean>) => {
      state.isDeleted = actions.payload;
      state.isShowDeletedWindow = false;
    },
    resetCurrentAppointment: (state) => {
      state.currentAppointment = null;
      state.isEdited = false;
      state.currentPatient = null;
    },
    selectAppointmentToDeleted: (
      state,
      action: PayloadAction<
        AppointmentResponseDto | AppointmentBriefResponseDto | null
      >,
    ) => {
      state.appointmentToDeleted = action.payload;
      state.deletedSuccess = false;
    },
    setMode: (state, action: PayloadAction<ReserveSlotsMode | null>) => {
      state.mode = action.payload;
    },
    setSlotsDetails: (state, action: PayloadAction<SlotsDetails>) => {
      state.mode = action.payload.mode;
      state.professionalId = action.payload.professionalId;
      state.appointmentId = action.payload.appointmentId;
      state.absenceId = action.payload.absenceId;
      state.startDate = action.payload.startDate;
      state.currentAppointment = null;
    },
    resetSlotsDetails: (state) => {
      state.mode = null;
      state.professionalId = undefined;
      state.appointmentId = undefined;
      state.absenceId = undefined;
      state.startDate = undefined;
      state.currentAppointment = null;
      state.waitingStartDate = undefined;
    },
    setCurrentAppointment: (state, action) => {
      state.currentAppointment = action.payload;
    },
    setWaitingStartDate: (state, action) => {
      state.waitingStartDate = action.payload;
    },
    setIsEndedAppointment: (state, action) => {
      state.isEndedAppointment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAppointment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createAppointment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createAppointment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isCreatedAppointment = true;
      state.currentAppointment = action.payload.appointment || null;
    });
    //Patients fetch
    builder.addCase(fetchAppointmentPatients.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAppointmentPatients.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchAppointmentPatients.fulfilled, (state, action) => {
      state.appointmentPatients = action.payload;
      state.isLoading = false;
    });
    //Delete appointment
    builder.addCase(deleteAppointment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAppointment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteAppointment.fulfilled, (state) => {
      state.isShowDeletedWindow = true;
      state.isLoading = false;
      state.deletedSuccess = true;
    });
    //get appointment by id
    builder.addCase(fetchAppointmentById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAppointmentById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(
      fetchAppointmentById.fulfilled,
      (state, action: PayloadAction<AppointmentResponseDto>) => {
        state.currentAppointment = action.payload;
        state.currentPatient = action.payload.patient!;
        state.isLoading = false;
      },
    );
    //Edit appointment
    builder.addCase(editAppointment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editAppointment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(
      editAppointment.fulfilled,
      (state, action: PayloadAction<AppointmentUpdateResponseDto>) => {
        state.currentAppointment = action.payload.appointment!;
        state.isEdited = true;
        state.isLoading = false;
      },
    );

    // fetch appointments in waiting list
    builder.addCase(fetchAppointmentsWaitingList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAppointmentsWaitingList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchAppointmentsWaitingList.fulfilled, (state, action) => {
      const entities = action.payload
        .entities as AppointmentWaitingListResponseDto[];
      state.waitingList = entities!;
      state.isLoading = false;
      state.totalPages = action.payload.totalPages;
    });

    // end appointments
    builder.addCase(endAppointment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(endAppointment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(endAppointment.fulfilled, (state) => {
      state.isEndedAppointment = true;
      state.isLoading = false;
    });

    // update appointment status
    builder.addCase(updateAppointmentStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAppointmentStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateAppointmentStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      if (state.currentAppointment) {
        state.currentAppointment.status = action.payload.status!;
        state.currentAppointment.statusComment =
          action.payload.statusComment || '';
      }
    });
    // fetch appointment request list
    builder.addCase(fetchAppointmentRequestList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAppointmentRequestList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchAppointmentRequestList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.requestList = action.payload.entities;
      state.total = action.payload.total || 0;
      state.page = action.payload.page || 0;
      state.totalPages = action.payload.totalPages || 0;
    });
    // delete appointment request list
    builder.addCase(deleteAppointmentRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAppointmentRequest.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteAppointmentRequest.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  setCurrentPatient,
  setPatients,
  setIsCreatedAppointment,
  setIsAppointmentDeleted,
  resetCurrentAppointment,
  selectAppointmentToDeleted,
  setSlotsDetails,
  resetSlotsDetails,
  setMode,
  setIsHiddenShowProfile,
  setCurrentAppointment,
  setWaitingStartDate,
  setCurrentFamilyMemberSearch,
  setIsEndedAppointment,
} = appointmentsSlice.actions;

export default appointmentsSlice.reducer;

import React, { FC } from 'react';
import { Wrapper, ErrorInfo } from './styles';
import { ReactComponent as ErrorIcon } from 'applicaiton/assets/errorIcon.svg';
import { PrimaryButton } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import {
  getIsUserPrivateDoctor,
  getToken,
  getUserId,
  getUserRole,
} from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getCurrentRoutes } from 'applicaiton/routes/routes';

const Error: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = getToken();
  const doShowAgenda = userHasPermission(UserPermissions.accessToOtherAgendas);
  const userRole = getUserRole();
  const isProfessionalRole = userRole === UserRoles.professional;
  const userId = getUserId();
  const isPrivateDoctor = getIsUserPrivateDoctor();
  const showAgendaOfOthers =
    (doShowAgenda && !isProfessionalRole) || isProfessionalRole;

  const homePage = getCurrentRoutes()[0]?.path || '';

  const goHome = () => {
    if (!token) {
      navigate(PathNames.login);
    }
    if (isPrivateDoctor) {
      navigate(homePage);
    } else {
      navigate(
        showAgendaOfOthers ? homePage : `${PathNames.profile}/${userId}`,
      );
    }
  };

  return (
    <Wrapper>
      <ErrorInfo>
        <ErrorIcon />
        <h2>404</h2>
        <h4>{t('global_error.not_found')}</h4>
        <p>{t('global_error.page_not_exist')}</p>
        <PrimaryButton onClick={goHome}>
          {t('global_error.to_home')}
        </PrimaryButton>
      </ErrorInfo>
    </Wrapper>
  );
};

export default Error;

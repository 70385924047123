import React from 'react';

import Modal from '@mui/material/Modal';

import { IModal } from './types';

import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { StyledModal } from './styles';
import Loader from '../Loader';

export const ModalComponent = ({
  isOpen,
  handleClose,
  children,
  padding,
  width = 'fit-content',
  maxWidth = '100%',
  hideCloseIcon = false,
  isLoading,
}: IModal) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <>
        {isLoading && <Loader />}
        <StyledModal width={width} maxWidth={maxWidth} padding={padding}>
          {!hideCloseIcon && <CloseIcon onClick={handleClose} />}
          {children}
        </StyledModal>
      </>
    </Modal>
  );
};

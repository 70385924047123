import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '../ButtonGroup';
import { Loader, PrimaryButton, SecondaryButton } from 'common/components';
import PaymentOrder from '../PaymentOrder';
import SEPAInfo from '../SEPAInfo/Index';
import { Wrapper } from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { clinicOwnerPayments } from 'applicaiton/store/reducers/ClinicRegistration/ActionCreators';
import { currentClinicOwnerRegistration } from 'applicaiton/sessionStorage/clinicRegistration';
import SuccessRegistrationModal from '../SuccessRegistrationModal';

interface PaymentProcessProps {
  setStep: (value: number) => void;
}

const PaymentProcess: FC<PaymentProcessProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, isSuccessClinicOwnerPayments } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );

  const [showModal, setShowModal] = useState(false);

  const data = currentClinicOwnerRegistration();

  useEffect(() => {
    if (isSuccessClinicOwnerPayments) {
      setShowModal(true);
    }
  }, [isSuccessClinicOwnerPayments]);

  const handleSubmit = async () => {
    await dispatch(
      clinicOwnerPayments({
        data: { paymentMethodId: data.setupIntent.payment_method! },
        token: data.stripeDebit.token,
      }),
    );
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <div>
        <section>
          <SEPAInfo />
          <PaymentOrder setStep={setStep} />
        </section>
      </div>
      <ButtonGroup>
        <PrimaryButton onClick={() => handleSubmit()} type="submit">
          {t('clinicRegistration.continue')}
        </PrimaryButton>
        <SecondaryButton
          onClick={() => setStep(4)}
          disabled={false}
          type="button"
        >
          {t('clinicRegistration.back')}
        </SecondaryButton>
      </ButtonGroup>
      {showModal && <SuccessRegistrationModal opened={showModal} />}
    </Wrapper>
  );
};

export default PaymentProcess;

import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { getCurrentRoutes } from 'applicaiton/routes/routes';
import { PathNames } from 'applicaiton/routes';

import MyProfile from 'common/components/MyProfile';
import { RoutesProps } from 'common/types/routes';

import SecondMenuItem from '../SecondMenuItem';

import { Wrapper } from './styles';

const SecondMenu: FC = () => {
  const location = useLocation();
  const routes: RoutesProps[] = getCurrentRoutes();
  const isSettingsPage = location.pathname.includes(PathNames.settings);
  const isSubscriptionsPage = location.pathname.includes(
    PathNames.subscriptions,
  );
  const showMenu = isSettingsPage || isSubscriptionsPage;
  const locationEndIndex = location.pathname.slice(1).indexOf('/');
  const locationMainPath = location.pathname.slice(0, locationEndIndex);

  const routesFiltered = routes.filter(
    (item) => item.path.includes(locationMainPath) && item.isSecondMenuItem,
  );

  return (
    <Wrapper showMenu={showMenu}>
      <MyProfile />
      <div className="accordion-list">
        {routesFiltered.map((item, index) => (
          <SecondMenuItem
            path={item.path}
            name={item.name || ''}
            key={index}
            Image={item.image}
          />
        ))}
      </div>
    </Wrapper>
  );
};

export default SecondMenu;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import { Loader } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import { ReactComponent as CopyIcon } from 'applicaiton/assets/content-copy.svg';
import { professionalProfileActions } from 'applicaiton/store/reducers/Professionals/ProfessionalProfileSlice';
import ClinicSchedule from '../ClinicSchedule';
import ClinicDropdownSection from '../ClinicDropdownSection';
import ButtonSection from '../ButtonSection';
import { Wrapper, DividerStyled, FormStyled, Header } from './styles';
import { ClinicInformationProps } from './modules';
import { useTranslation } from 'react-i18next';
import {
  ClinicProfessionalUpdateDto,
  ClinicResponseDto,
} from '@docbay/schemas';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  fetchProfessionalById,
  updateProfessional,
} from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { fetchAppointmentTypes } from 'applicaiton/store/reducers/AppointmentTypes/ActionCreators';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { isDisabledEditProfessional } from 'common/helpers/permissions';
import { ProfessionalClinicRelationI, ProfessionalEditClinicT } from './types';
import { resetProfessionalAppointmentTypes } from 'applicaiton/store/reducers/AppointmentTypes/AppointmentTypesSlice';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { ClinicProfessionalActiveToggle } from '../ClinicProfessionalActiveToggle';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import { featuresPermissions } from 'common/helpers/featuresPermissions';

const ClinicInformation: FC<ClinicInformationProps> = ({
  arrayIndex,
  handleDelete,
  addClinic,
  allClinics,
  setAllClinics,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userId = getUserId();
  const userRole = getUserRole();
  const { hasDoctorWorkingHoursConfigurationPermission } =
    featuresPermissions();
  const [chosenClinic, setChosenClinic] = useState<
    ClinicResponseDto | undefined
  >(undefined);
  const [clinicActiveRelations, setClinicActiveRelations] = useState<
    ProfessionalClinicRelationI[]
  >([]);
  const [addedAppointmentTypes, setAddedAppointmentTypes] = useState<string[]>(
    [],
  );
  const { editedProfessional, isLoading } = useAppSelector(
    (state) => state.professionalProfile,
  );
  const { clinics, isLoading: isLoadingClinics } = useAppSelector(
    (state) => state.clinics,
  );
  const { isLoading: isLoadingAppointmentsType } = useAppSelector(
    (state) => state.appointmentTypes,
  );
  const { isLoading: isLoadingSpecializations } = useAppSelector(
    (state) => state.specializations,
  );
  const { isPrivateDoctor } = useClinicOwner();
  const isProfessional = userRole === UserRoles.professional;
  const currentClinic = allClinics[arrayIndex];
  const canUserDeleteClinic = useMemo(() => {
    const isNewClinic = !currentClinic.clinicId;
    if (isNewClinic) {
      return true;
    }
    const currentClinicOwnerId = clinics.find(
      (clinic) => clinic.id === currentClinic.clinicId,
    )?.owner?.id;
    const clinicsByOwnerId = clinics.filter(
      (clinic) => clinic.owner?.id === currentClinicOwnerId,
    );
    if (isProfessional) {
      const canDeleteClinic = clinicsByOwnerId.length > 1;
      return canDeleteClinic;
    } else {
      const canDeleteClinic =
        clinicsByOwnerId.filter((clinic) => {
          const currentProfessionalRelations =
            clinic.professionalsRelations?.filter(
              (item) => item.professional?.id === editedProfessional?.id,
            );

          return !!currentProfessionalRelations?.length;
        }).length > 1;
      return canDeleteClinic;
    }
  }, [clinics, currentClinic, isProfessional]);

  const disableEditing = isDisabledEditProfessional(
    (editedProfessional?.clinicOwnerIds &&
      editedProfessional?.clinicOwnerIds.length === 1) ||
      false,
    editedProfessional?.clinicOwnerIds?.includes(id!) ||
      editedProfessional?.clinicOwnerIds?.includes(userId!) ||
      userId === editedProfessional?.id,
  );

  const hasPermissionManageWorkingHours =
    userHasPermission(UserPermissions.professionalOpeningHours) &&
    hasDoctorWorkingHoursConfigurationPermission;

  const {
    setValue,
    getValues,
    watch,
    handleSubmit,
    clearErrors,
    setError,
    control,
    reset,
    formState: { errors },
  } = useFormContext<ProfessionalEditClinicT>();

  const formData = useWatch<ProfessionalEditClinicT>({ control });

  useEffect(() => {
    setChosenClinic(getChosenClinic(getValues('clinic')));
  }, [watch('clinic')]);

  useEffect(() => {
    if (
      isPrivateDoctor &&
      editedProfessional &&
      editedProfessional.clinicsRelations
    ) {
      const currentClinic = editedProfessional.clinicsRelations[0]!;

      setChosenClinic(currentClinic.clinic!);
    }
  }, [isPrivateDoctor, editedProfessional]);

  const getAddedAppointmentTypes = async () => {
    const specializationsIds = currentClinic?.specializationsToAdd
      ?.map((item) => String(item))
      .join();

    await dispatch(
      fetchAppointmentTypes({
        clinicId: currentClinic.clinicId,
        ...(specializationsIds ? { specializations: specializationsIds } : {}),
        professionalIds: String(editedProfessional?.id),
        onlyProfessional: true,
      }),
    ).then((data) => {
      const types: any = data.payload;
      const options = types.entities.map((item: any) => item?.id);
      setValue('appointmentTypes', options);
      setAddedAppointmentTypes(options);
    });
  };

  useEffect(() => {
    if (currentClinic.id) {
      setValue('clinic', currentClinic.clinicId || '');
      setValue('specializationsToAdd', currentClinic.specializationsToAdd!);
      !isPrivateDoctor &&
        setChosenClinic(getChosenClinic(currentClinic.clinicId));
      getAddedAppointmentTypes();
    } else {
      reset();
      setChosenClinic(undefined);
      dispatch(resetProfessionalAppointmentTypes());
    }
  }, [arrayIndex, clinics]);

  const enabledWorkingHours =
    chosenClinic?.openingHours
      ?.filter((item) => item.isEnabled)
      .map((item) => ({
        ...item,
        day: t(`${item.day.toLocaleLowerCase()}`),
      })) || [];

  const getChosenClinic = (id: string) => {
    const chosenClinic = clinics.find((clinic) => String(clinic.id) === id);
    return chosenClinic;
  };

  const handleUpdateClinicList = () => {
    const {
      specializationsToAdd,
      hours,
      appointmentTypes,
      clinic,
      appointmentTypesToDelete,
    } = getValues();

    const clinicsToDeleteIds = (editedProfessional!.clinicsRelations || [])
      .filter((clinic) => {
        const findId = allClinics.find((item) => item.id === clinic.id);
        return !findId;
      })
      .map((clinic) => clinic.id!);

    const updatedClinicsRelations = allClinics.map((clinicItem) => {
      const activeStatusRelation = clinicActiveRelations.find(
        (relation) => relation.clinicId === clinicItem.clinicId,
      );
      if (activeStatusRelation) {
        clinicItem.active = activeStatusRelation.active;
      }
      if (clinicItem.id === currentClinic.id) {
        const existingSpecializationsToAdd =
          clinicItem.specializationsToAdd || [];

        const specializationsToDelete = existingSpecializationsToAdd.filter(
          (specializationId) =>
            !specializationsToAdd.includes(specializationId),
        );

        const updatedSpecializationsToAdd = specializationsToAdd.filter(
          (specializationId: string) =>
            !existingSpecializationsToAdd.includes(specializationId),
        );

        return {
          ...(currentClinic.id && { id: currentClinic.id }),
          clinicId: clinic,
          active: clinicItem?.active,
          specializationsToAdd: updatedSpecializationsToAdd,
          specializationsToDelete: specializationsToDelete,
          appointmentTypesToAdd: appointmentTypes,
          ...(appointmentTypesToDelete.length
            ? { appointmentTypesToDelete: appointmentTypesToDelete }
            : {}),
          schedule: {
            hours: hours,
          },
        };
      }
      return clinicItem;
    });
    setAllClinics(updatedClinicsRelations);
    return {
      clinicsRelations: updatedClinicsRelations,
      clinicRelationsToDelete: clinicsToDeleteIds || [],
    };
  };

  const handleSave = async () => {
    await dispatch(
      updateProfessional({
        id: editedProfessional?.id! || '',
        professional: handleUpdateClinicList(),
      }),
    );
    await dispatch(fetchProfessionalById(String(editedProfessional?.id)));
  };

  const handleCancel = () => {
    dispatch(professionalProfileActions.setProfileChanges(null));
    navigate(PathNames.professionals);
  };

  const getCurrentClinicData = () => {
    const { clinic, specializationsToAdd, hours, appointmentTypes } =
      getValues();
    const newClinicData: ClinicProfessionalUpdateDto = {
      clinicId: clinic,
      specializationsToAdd,
      appointmentTypesToAdd: appointmentTypes,
      schedule: {
        ...currentClinic.schedule,
        hours: hours,
      },
    };
    return newClinicData;
  };

  const handleDuplicate = () => {
    if (!Object.keys(errors).length) {
      const newClinicData = getCurrentClinicData();
      const updatedClinics = [...allClinics, newClinicData];
      setAllClinics(updatedClinics);
      addClinic();
    }
  };

  const handleDeleteClinic = async () => {
    const response = await dispatch(
      updateProfessional({
        id: editedProfessional?.id! || '',
        professional: { clinicRelationsToDelete: [currentClinic.id || ''] },
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      const updatedClinics = [...allClinics];
      updatedClinics.pop();
      setAllClinics(updatedClinics);
      handleDelete();
    }
  };

  const isStateChanged = useMemo(() => {
    const {
      appointmentTypes,
      clinic,
      hours,
      appointmentTypesToDelete,
      specializationsToAdd,
    } = getValues();

    const defaultData = {
      appointmentTypes: addedAppointmentTypes,
      clinicId: currentClinic.clinicId,
      hours:
        currentClinic.schedule?.hours.map((item) => ({
          day: item.day,
          isEnabled: item.isEnabled,
          slots: item.slots,
        })) || [],
      specializationsToAdd: currentClinic.specializationsToAdd,
    };
    const currentChanges = {
      appointmentTypes,
      clinicId: clinic,
      hours,
      specializationsToAdd,
      ...(appointmentTypesToDelete.length ? { appointmentTypesToDelete } : {}),
    };

    const hasChanges = compareObjectsChanges(currentChanges, defaultData, true);
    return hasChanges || clinicActiveRelations.length > 0;
  }, [
    editedProfessional?.clinicsRelations,
    formData,
    currentClinic,
    addedAppointmentTypes,
    clinicActiveRelations,
  ]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const isClinicActive = useMemo(() => {
    const selectedRelation = clinicActiveRelations.find(
      (item) => item.clinicId === chosenClinic?.id,
    );
    if (selectedRelation) {
      return selectedRelation.active;
    }
    const clinicRelation = editedProfessional?.clinicsRelations?.find(
      (item) => item.clinic?.id === currentClinic.clinicId,
    );
    return clinicRelation?.active || false;
  }, [clinicActiveRelations, chosenClinic]);

  const toggleClinicActive = (isActive: boolean) => {
    if (!chosenClinic?.id) return;
    const newRelations = clinicActiveRelations.filter(
      (rel) => rel.clinicId !== chosenClinic.id,
    );
    setClinicActiveRelations([
      ...newRelations,
      { clinicId: chosenClinic?.id, active: isActive },
    ]);
  };

  const isDataLoading = useMemo(() => {
    return (
      isLoading ||
      isLoadingClinics ||
      isLoadingSpecializations ||
      isLoadingAppointmentsType
    );
  }, [
    isLoading,
    isLoadingClinics,
    isLoadingSpecializations,
    isLoadingAppointmentsType,
  ]);

  return (
    <>
      {isDataLoading && <Loader />}
      <Wrapper>
        <Header>
          <h1>{chosenClinic?.name || t('professional_profile.new_clinic')}</h1>
          <div>
            <button
              disabled={false}
              type="button"
              onClick={handleSubmit(handleDuplicate)}
            >
              <CopyIcon />
              <h2>{t('professional_profile.duplicate_settings')}</h2>
            </button>
            {canUserDeleteClinic && (
              <button
                disabled={false}
                type="button"
                onClick={handleDeleteClinic}
              >
                <DeleteIcon />
                <h2>{t('delete')}</h2>
              </button>
            )}
          </div>
        </Header>
        <ClinicProfessionalActiveToggle
          isActive={isClinicActive}
          setIsActive={toggleClinicActive}
        />
        <DividerStyled />
        <FormStyled>
          <ClinicDropdownSection
            getValues={getValues}
            clearErrors={clearErrors}
            watch={watch}
            setValue={setValue}
            errors={errors}
            clinic={currentClinic}
            arrayIndex={arrayIndex}
            disableEditing={disableEditing}
            hideClinicDropdown={isPrivateDoctor}
          />
          <DividerStyled />
          {hasPermissionManageWorkingHours && (
            <ClinicSchedule
              setError={setError}
              clearErrors={clearErrors}
              setValue={setValue}
              arrayIndex={arrayIndex}
              enabledWorkingHours={enabledWorkingHours}
              chosenClinic={chosenClinic}
              allClinics={allClinics}
              allowEmptySchedule={true}
            />
          )}
        </FormStyled>
        <div>
          <DividerStyled />
          <ButtonSection
            onSave={handleSubmit(handleSave)}
            onCancel={handleCancel}
            disabled={!!Object.keys(errors).length || !isStateChanged}
          />
        </div>
      </Wrapper>
    </>
  );
};

export default ClinicInformation;

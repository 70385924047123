export enum FeaturesEnum {
  Agenda = 'Agenda',
  AppointmentTypeConfiguration = 'AppointmentTypeConfiguration',
  DocboxChats = 'DocboxChats',
  DocboxSubscriptionConfiguration = 'DocboxSubscriptionConfiguration',
  DocboxSubscribes = 'DocboxSubscribes',
  StaffManagement = 'StaffManagement',
  Teleconsultation = 'Teleconsultation',
  WaitingList = 'WaitingList',
  Invoicing = 'Invoicing',
  PatientSMSNotifications = 'PatientSMSNotifications',
  PatientEmailNotifications = 'PatientEmailNotifications',
  PatientPushNotifications = 'PatientPushNotifications',
  ClinicOwnerSMSNotifications = 'ClinicOwnerSMSNotifications',
  ClinicOwnerEmailNotifications = 'ClinicOwnerEmailNotifications',
  ClinicOwnerPushNotifications = 'ClinicOwnerPushNotifications',
  DoctorSMSNotifications = 'DoctorSMSNotifications',
  DoctorEmailNotifications = 'DoctorEmailNotifications',
  DoctorPushNotifications = 'DoctorPushNotifications',
  AdminSMSNotifications = 'AdminSMSNotifications',
  AdminEmailNotifications = 'AdminEmailNotifications',
  AdminPushNotifications = 'AdminPushNotifications',
  SecretarySMSNotifications = 'SecretarySMSNotifications',
  SecretaryEmailNotifications = 'SecretaryEmailNotifications',
  SecretaryPushNotifications = 'SecretaryPushNotifications',
  AppointmentRequest = 'AppointmentRequest',
  PatientClinicalFile = 'PatientClinicalFile',
  DoctorWorkingHoursConfiguration = 'DoctorWorkingHoursConfiguration',
  DoctorManagement = 'DoctorManagement',
}

import React, { FC, JSXElementConstructor } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MenuItemStyled } from './styles';

interface MenuItemProps {
  path: string;
  name: string;
  Image?: JSXElementConstructor<any>;
  isNotificationItem?: boolean;
  isDocBoxItem?: boolean;
  availablePaths?: string[];
}

const SecondMenuItem: FC<MenuItemProps> = ({ path, name, Image }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isCurrentPage = location.pathname === path;
  const isPageActive = location.pathname.includes(path);

  const handleChangePath = () => {
    if (!isCurrentPage) {
      path && navigate(path);
    }
  };

  return (
    <MenuItemStyled>
      <button
        onClick={handleChangePath}
        className={`${isPageActive ? 'active' : ''}`}
      >
        <>
          {Image && <Image />}
          {name}
        </>
      </button>
    </MenuItemStyled>
  );
};

export default SecondMenuItem;

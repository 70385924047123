import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { PathNames } from 'applicaiton/routes/constants';
import { setShowLimitSubscriptionsModal } from 'applicaiton/store/reducers/ClinicOwner/ClinicOwnerSlice';

import { PrimaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { isUserCanManageProfessional } from 'common/helpers/permissions';
import PageSwitcher from 'common/components/PageSwitcher';
import { featuresPermissions } from 'common/helpers/featuresPermissions';

import { ProfessionalsTable, Header, InfoSection } from './components';
import { ProfessionalsListStyled } from './styles';

const ProfessionalsList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { canAddNewUser } = useAppSelector((state) => state.clinicOwnerSlice);
  const isCanManageProfessional = isUserCanManageProfessional();
  const { hasStaffManagementPermission } = featuresPermissions();

  const handleShowModal = () => {
    if (canAddNewUser) return;
    dispatch(setShowLimitSubscriptionsModal(true));
  };

  return (
    <ProfessionalsListStyled>
      <Header />
      {hasStaffManagementPermission && (
        <PageSwitcher>
          <div>{t('clinicsConfiguration.aside_menu.doctors')}</div>
          <Link to={PathNames.myUsers}>
            {t('clinicsConfiguration.aside_menu.admin_staff')}
          </Link>
        </PageSwitcher>
      )}
      <div>
        <InfoSection />
        {isCanManageProfessional && (
          <Link
            to={canAddNewUser ? PathNames.addProfessional : ''}
            onClick={handleShowModal}
          >
            <PrimaryButton>
              <AddIcon />
              {t('professionalsListConfiguration.add_new_professional')}
            </PrimaryButton>
          </Link>
        )}
      </div>
      <ProfessionalsTable />
    </ProfessionalsListStyled>
  );
};

export default ProfessionalsList;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { updateOnlineHours } from 'applicaiton/store/reducers/OnlineHours/ActionCreators';

import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader, PrimaryButton, TimeRange } from 'common/components';
import { OpeningHours, TimeSlots } from 'common/types/clinics';
import { getSchedule } from 'common/helpers/getSchedule';

import {
  DividerStyled,
  SlotsWrapper,
  Wrapper,
  SlotsContainer,
  ReplicateButton,
  ButtonSection,
} from './styles';

const OnlineHours: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    onlineStatusVisible,
    professionalId,
    defaultState,
    onlineHours,
  } = useAppSelector((state) => state.onlineHours);

  const [openingHours, setOpeningHours] = useState<OpeningHours[]>(
    getSchedule({}),
  );

  useEffect(() => {
    if (onlineHours.length) {
      const currentOpeningHours = getSchedule({}).map((day) => {
        return {
          ...day,
          isEnabled: false,
          ...(onlineHours?.find((item) => item.day === day.day) || {}),
        };
      });
      setOpeningHours(currentOpeningHours);
    }
  }, [onlineHours]);

  const handleAllDays = () => {
    const firstDay = openingHours.find((item) => item.day === 'Monday')!;

    const currentOpeningHours = openingHours.map((day) => {
      if (day.day === 'Saturday' || day.day === 'Sunday') {
        return day;
      }

      return {
        day: day.day,
        isEnabled: firstDay.isEnabled!,
        slots: firstDay.slots!,
      };
    });

    setOpeningHours(currentOpeningHours);
  };

  const handleAddSlot = (day: string) => {
    const newSlot = {
      startTime: '',
      endTime: '',
    };

    setOpeningHours((prev) => [
      ...prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            slots: [...item.slots, newSlot],
          };
        }
        return item;
      }),
    ]);
  };

  const handleEditSlot = (day: string, index: number, value: TimeSlots) => {
    const openingHoursEdited = openingHours?.map((item, i) => {
      if (item.day === day) {
        return {
          ...item,
          slots: item.slots.map((slot, i) => {
            if (index === i) {
              return { ...slot, ...value };
            }
            return slot;
          }),
        };
      }
      return item;
    });

    setOpeningHours(openingHoursEdited);
  };

  const handleEnableSlot = (day: string, value: boolean) => {
    setOpeningHours((prev) =>
      prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            isEnabled: value,
          };
        }
        return item;
      }),
    );
  };

  const handleRemoveSlot = (day: string, index: number) => {
    setOpeningHours((prev) =>
      prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            slots: item.slots.filter((slot, i) => index !== i),
          };
        }
        return item;
      }),
    );
  };

  const getFilteredOpeningHours = () => {
    const filteredOpeningHours = openingHours.filter((item) => {
      const hasValidSlot = item.slots.some(
        (slot) => slot.startTime && slot.endTime,
      );
      return !!hasValidSlot;
    });
    return filteredOpeningHours;
  };

  const handleSave = async () => {
    if (!professionalId) return;
    const filteredOpeningHours = getFilteredOpeningHours();
    dispatch(
      updateOnlineHours({
        id: professionalId,
        data: {
          onlineHours: filteredOpeningHours,
          onlineStatusVisible,
        },
      }),
    );
  };

  const isStateChanged = useMemo(() => {
    const filteredOpeningHours = getFilteredOpeningHours().map((item) => ({
      day: item.day,
      isEnabled: item.isEnabled,
      slots: item.slots,
    }));
    const hasChanges = compareObjectsChanges(
      { onlineHours: filteredOpeningHours, onlineStatusVisible },
      {
        onlineHours: defaultState.onlineHours.map((item) => ({
          day: item.day,
          isEnabled: item.isEnabled,
          slots: item.slots,
        })),
        onlineStatusVisible: defaultState.onlineStatusVisible,
      },
    );

    return hasChanges;
  }, [openingHours, onlineStatusVisible, defaultState]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const isSaveButtonDisabled = !isStateChanged;

  const isReplicateDisabledButton = useMemo(() => {
    if (!openingHours.length) return true;
    const firstDay = openingHours.find((item) => item.day === 'Monday');
    return !firstDay?.slots[0]?.startTime || !firstDay?.slots[0]?.endTime;
  }, [openingHours]);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <SlotsContainer>
        <SlotsWrapper>
          {openingHours.map((item, index) => (
            <TimeRange
              key={index}
              day={item.day}
              slots={item.slots}
              isEnabled={item.isEnabled}
              handleAddSlot={handleAddSlot}
              handleEditSlot={handleEditSlot}
              handleRemoveSlot={handleRemoveSlot}
              handleEnableSlot={handleEnableSlot}
            />
          ))}
        </SlotsWrapper>
        <ReplicateButton
          disabled={isReplicateDisabledButton}
          onClick={handleAllDays}
        >
          {t('clinicsConfiguration.clinics.replicate')}
        </ReplicateButton>
      </SlotsContainer>

      <DividerStyled />
      <ButtonSection>
        <PrimaryButton
          type="button"
          onClick={handleSave}
          disabled={isSaveButtonDisabled}
        >
          {t('save')}
        </PrimaryButton>
      </ButtonSection>
    </Wrapper>
  );
};

export default OnlineHours;

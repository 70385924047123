import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { PathNames } from 'applicaiton/routes';

import { PrimaryButton } from 'common/components';
import PageSwitcher from 'common/components/PageSwitcher';
import { featuresPermissions } from 'common/helpers/featuresPermissions';

import { UsersTable, Header, InfoSection, AddUserModal } from './components';
import { UsersListStyled } from './styles';

const UsersList: FC = () => {
  const { t } = useTranslation();
  const { hasDoctorManagementPermission } = featuresPermissions();
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowModal = () => {
    setShowAddModal(true);
  };

  return (
    <UsersListStyled>
      <Header />
      {hasDoctorManagementPermission && (
        <PageSwitcher>
          <Link to={PathNames.professionals}>
            {t('clinicsConfiguration.aside_menu.doctors')}
          </Link>
          <div>{t('clinicsConfiguration.aside_menu.admin_staff')}</div>
        </PageSwitcher>
      )}
      <div>
        <InfoSection />
        <div>
          <PrimaryButton onClick={handleShowModal}>
            <AddIcon />
            {t('adminsListConfiguration.add_new_user')}
          </PrimaryButton>
        </div>
      </div>
      <UsersTable />
      <AddUserModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
      />
    </UsersListStyled>
  );
};

export default UsersList;

import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import type { LoginDataT } from './types';
import { useLoginForm } from './hooks/useLoginForm';
import { Checkbox, Input, Loader, PrimaryButton } from 'common/components';

import {
  CheckboxStyled,
  CheckboxWrapper,
  ForgotPasswordWrapper,
  FormGroupStyled,
  LoginFormWrapper,
  StyledBlueLink,
  StyledError,
  StyledForm,
} from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import { setAuthError } from 'applicaiton/store/reducers/Users/UsersSlice';
import {
  getUserProfile,
  usersLogin,
} from 'applicaiton/store/reducers/Users/ActionCreators';
import ForgotPasswordFooter from './components/ForgotPasswordFooter';
import Header from './components/Header';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { SupportedLanguageIso } from '@docbay/schemas';
import { ReactComponent as LoginIcon } from 'applicaiton/assets/login.svg';
import { login } from 'common/auth/authService';
import {
  getIsUserPrivateDoctor,
  getToken,
  getUserId,
  getUserRole,
} from 'applicaiton/sessionStorage/auth';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getCurrentRoutes } from 'applicaiton/routes/routes';

const LoginPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error, isAuth, isLoading } = useAppSelector((state) => state.users);
  const [loading, setLoading] = useState(false);

  const doShowAgenda = userHasPermission(UserPermissions.accessToOtherAgendas);
  const userRole = getUserRole();
  const isProfessionalRole = userRole === UserRoles.professional;
  const isPrivateDoctor = getIsUserPrivateDoctor();
  const userId = getUserId();
  const showAgendaOfOthers =
    (doShowAgenda && !isProfessionalRole) || isProfessionalRole;

  const {
    register,
    errors,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useLoginForm({
    emailError: t('errors.emailFormat'),
    requiredError: t('errors.required'),
  });

  useEffect(() => {
    if (error) {
      setError('email', { message: ' ' });
      setError('password', { message: ' ' });
    } else {
      clearErrors();
    }
  }, [error]);

  useEffect(() => {
    const token = getToken();
    if (isAuth || token) {
      if (!isPrivateDoctor) {
        const homePath = getCurrentRoutes()[0]?.path;
        navigate(
          showAgendaOfOthers ? homePath : `${PathNames.profile}/${userId}`,
        );
      }
    }
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(setAuthError(false));
    }
  }, [watch('email'), watch('password')]);

  const onSubmit = async (data: LoginDataT) => {
    const language = getLanguageFromLocalStorage();

    const response = await dispatch(
      usersLogin({
        email: data.email,
        password: data.password,
        languageIso: language as SupportedLanguageIso,
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      const res = await dispatch(getUserProfile());
      if (res.meta.requestStatus === 'fulfilled') {
        setLoading(true);
        // Delay first refresh token call to avoid Safari issue
        setTimeout(() => {
          setLoading(false);
          login();
        }, 500);
      }
    }
  };

  return (
    <LoginFormWrapper>
      {(isLoading || loading) && <Loader />}
      <LoginIcon />
      <Header />

      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FormGroupStyled>
          <Input
            id="email"
            label={t('auth.email') || ''}
            type="email"
            placeholder={t('email_placeholder') || ''}
            register={register}
            errorMessage={errors.email?.message}
          />
          <Input
            id="password"
            label={t('auth.log_in.password_label') || ''}
            type="password"
            placeholder={t('auth.log_in.password_label') || ''}
            errorMessage={errors.password?.message}
            register={register}
          />
        </FormGroupStyled>

        <CheckboxWrapper>
          <CheckboxStyled key={t('auth.log_in.remember_me')}>
            <Checkbox
              id={'remember'}
              checked={watch('remember') || false}
              onChange={(checked) => setValue('remember', checked)}
              label={t('auth.log_in.remember_me') || ''}
            />
          </CheckboxStyled>
          <StyledBlueLink to={PathNames.forgotPassword}>
            {t('auth.log_in.forgot_password')}
          </StyledBlueLink>
        </CheckboxWrapper>

        <PrimaryButton
          onClick={handleSubmit(onSubmit)}
          type="submit"
          disabled={!!Object.keys(errors).length}
        >
          {t('auth.log_in.log_in_button')}
        </PrimaryButton>
      </StyledForm>

      {error && (
        <StyledError variant="body1">
          {t('auth.log_in.incorrect_credentials')}
        </StyledError>
      )}
      <ForgotPasswordWrapper error={error ? 1 : 0}>
        <ForgotPasswordFooter />
      </ForgotPasswordWrapper>
    </LoginFormWrapper>
  );
};

export default LoginPage;

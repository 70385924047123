import { StorageFields } from 'applicaiton/constants/localStorage';
import {
  AuthLocalStorage,
  getIsShowMyAgenda,
  getIsUserPrivateDoctor,
  getUserId,
  getUserRole,
} from 'applicaiton/sessionStorage/auth';
import { store } from 'applicaiton/store';
import { clearStore } from 'applicaiton/store/reducers/Users/UsersSlice';
import { PathNames } from 'applicaiton/routes';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getCurrentRoutes } from 'applicaiton/routes/routes';

const logoutChannel = new BroadcastChannel('logout');
const loginChannel = new BroadcastChannel('login');

export const logout = () => {
  logoutChannel.postMessage('Logout');
  window.location.href = window.location.origin + PathNames.login;
  store?.dispatch(clearStore());
};
export const login = () => {
  const doShowAgenda = userHasPermission(UserPermissions.accessToOtherAgendas);
  const userRole = getUserRole();
  const userId = getUserId();
  const isProfessionalRole = userRole === UserRoles.professional;
  const isShowMyAgenda = getIsShowMyAgenda();
  const isPrivateDoctor = getIsUserPrivateDoctor();

  const homePath = getCurrentRoutes()[0]?.path;

  const showAgendaOfOthers =
    (doShowAgenda && !isProfessionalRole) || isProfessionalRole;

  loginChannel.postMessage('Login');

  if (isPrivateDoctor) {
    isShowMyAgenda
      ? (window.location.href = window.location.origin + homePath)
      : (window.location.href =
          window.location.origin + `${PathNames.profile}/${userId}`);
  } else {
    showAgendaOfOthers &&
      (window.location.href = window.location.origin + homePath);
  }

  sessionStorage.removeItem('schedulerFilters');
};

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    logout();
    logoutChannel.close();
  };
};
export const loginAllTabs = () => {
  loginChannel.onmessage = () => {
    login();
    loginChannel.close();
  };
};

export const authService = {
  saveAccessToken(jwt: string) {
    localStorage.setItem(StorageFields.fbToken, jwt);
  },

  getAccessToken() {
    return localStorage.getItem(StorageFields.fbToken);
  },

  checkAuth() {
    return !!localStorage.getItem(AuthLocalStorage.accessToken);
  },

  removeToken() {
    localStorage.removeItem(AuthLocalStorage.accessToken);
  },

  saveEmail(email: string) {
    localStorage.setItem(StorageFields.email, email);
  },

  getEmail() {
    return localStorage.getItem(StorageFields.email);
  },

  saveCode(code: number) {
    localStorage.setItem(StorageFields.verificationCode, String(code));
  },

  getEmailVerifiationCode() {
    return localStorage.getItem(StorageFields.verificationCode);
  },

  saveTimer(timer: string) {
    localStorage.setItem(StorageFields.timer, timer);
  },

  getTimer() {
    return localStorage.getItem(StorageFields.timer);
  },

  removeTimer() {
    localStorage.removeItem(StorageFields.timer);
  },

  cleanStorage() {
    localStorage.clear();
    sessionStorage.clear();
  },

  eraseSessionData() {
    this.removeToken();
  },
};

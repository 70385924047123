import React, { FC, useEffect, useMemo, useState } from 'react';
import { TimeRange } from 'common/components';
import { OpeningHours, TimeSlots } from 'common/types/clinics';
import {
  Wrapper,
  SlotsWrapper,
  WorkingSchedule,
  WorkingDays,
  WorkingHours,
  ScheduleFieldItem,
  SlotsContainer,
  ReplicateButton,
} from './styles';
import { getSchedule } from 'common/helpers/getSchedule';
import { useFormContext } from 'react-hook-form';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { useTranslation } from 'react-i18next';
import { ClinicScheduleProps } from './modules';
import dayjs from 'dayjs';

const ClinicSchedule: FC<ClinicScheduleProps> = ({
  setValue,
  arrayIndex,
  clearErrors,
  chosenClinic,
  enabledWorkingHours,
  allClinics,
  allowEmptySchedule,
}) => {
  const { t } = useTranslation();
  const [openingHours, setOpeningHours] = useState<OpeningHours[]>(() =>
    getSchedule({}),
  );

  const {
    formState: { errors },
  } = useFormContext();
  const currentClinic = allClinics[arrayIndex];
  const clinicScheduleHours = currentClinic.schedule?.hours || [];

  useEffect(() => {
    if (!clinicScheduleHours?.length && !allowEmptySchedule) {
      setOpeningHours(getSchedule({}));
    } else {
      const defaultSchedule = getSchedule({});

      const result = defaultSchedule.map((day) => {
        return {
          ...day,
          isEnabled: false,
          ...(clinicScheduleHours.find((day2) => day2.day === day.day) || {}),
        };
      });

      setOpeningHours(result);
    }
  }, [arrayIndex]);

  const handleAddSlot = (day: string) => {
    const newSlot = {
      startTime: '',
      endTime: '',
    };

    setOpeningHours((prev) => [
      ...prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            slots: [...item.slots, newSlot],
          };
        }
        return item;
      }),
    ]);
  };

  const handleEditSlot = (day: string, index: number, value: TimeSlots) => {
    const openingHoursEdited = openingHours?.map((item) => {
      if (item.day === day) {
        return {
          ...item,
          slots: item.slots.map((slot, i) => {
            if (index === i) {
              return { ...slot, ...value };
            }
            return slot;
          }),
        };
      }
      return item;
    });

    setOpeningHours(openingHoursEdited);
  };

  const handleEnableSlot = (day: string, value: boolean) => {
    setOpeningHours((prev) =>
      prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            isEnabled: value,
          };
        }
        return item;
      }),
    );
  };

  const handleRemoveSlot = (day: string, index: number) => {
    setOpeningHours((prev) =>
      prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            slots: item.slots.filter((slot, i) => index !== i),
          };
        }
        return item;
      }),
    );
  };

  const isStateChanged = useMemo(() => {
    const hasChanges = compareObjectsChanges(
      { openingHours },
      {
        openingHours: clinicScheduleHours,
      },
    );
    return hasChanges;
  }, [openingHours, clinicScheduleHours]);

  const scheduleValidation = (hours: OpeningHours[]) => {
    let isValid: boolean = true;

    hours.map((item) => {
      if (item.isEnabled) {
        item.slots.some((slot) => {
          if (!slot.endTime?.length || !slot.startTime?.length) {
            isValid = false;
          }
        });
      }
    });

    return isValid;
  };

  const handleScheduleChange = (hours: OpeningHours[]) => {
    let isValid: boolean = scheduleValidation(hours);

    const newArr = hours.filter((item) => item.isEnabled);

    if (isValid) {
      setValue('hours', newArr);
      clearErrors('hours');
    } else {
      setValue('hours', []);
    }
  };

  useEffect(() => {
    if (openingHours.length && isStateChanged) {
      handleScheduleChange(openingHours);
    }
  }, [openingHours]);

  const handleAllDays = () => {
    const firstDay = openingHours.find((item) => item.day === 'Monday')!;

    const currentOpeningHours = openingHours.map((day) => {
      if (day.day === 'Saturday' || day.day === 'Sunday') {
        return day;
      }

      return {
        day: day.day,
        isEnabled: firstDay.isEnabled!,
        slots: firstDay.slots!,
      };
    });

    setValue('hours', currentOpeningHours);
    clearErrors('hours');
    setOpeningHours(currentOpeningHours);
  };

  const isDisabledButton = useMemo(() => {
    if (!openingHours.length) return true;

    const firstDay = openingHours.find((item) => item.day === 'Monday');

    return !firstDay?.slots[0]?.startTime || !firstDay?.slots[0]?.endTime;
  }, [openingHours]);

  return (
    <Wrapper>
      <ScheduleFieldItem isError={!!errors?.hours}>
        <label>{t('professional_profile.agenda_hours')}</label>
        <SlotsContainer>
          <SlotsWrapper>
            {openingHours.map((item, index) => (
              <TimeRange
                key={index}
                day={item.day}
                slots={item.slots}
                isEnabled={item.isEnabled}
                handleAddSlot={handleAddSlot}
                handleEditSlot={handleEditSlot}
                handleRemoveSlot={handleRemoveSlot}
                handleEnableSlot={handleEnableSlot}
              />
            ))}
          </SlotsWrapper>
          <div>
            <ReplicateButton
              disabled={isDisabledButton}
              onClick={handleAllDays}
            >
              {t('clinicsConfiguration.clinics.replicate')}
            </ReplicateButton>
            {!!chosenClinic && enabledWorkingHours.length ? (
              <WorkingSchedule>
                <h2>
                  {`${t(
                    'professional_profile.overview_of_place_opening_hours',
                  )} ${chosenClinic.name}:`}
                </h2>
                <WorkingDays>
                  {enabledWorkingHours!.map((item) => (
                    <div key={item.day}>
                      <p>{item.day}</p>
                      <WorkingHours>
                        {item.slots.map((hour, index) => (
                          <p key={index}>
                            {dayjs(hour.startTime, 'HH:mm').format('HH:mm')} -{' '}
                            {dayjs(hour.endTime, 'HH:mm').format('HH:mm')}
                          </p>
                        ))}
                      </WorkingHours>
                    </div>
                  ))}
                </WorkingDays>
              </WorkingSchedule>
            ) : (
              ''
            )}
          </div>
        </SlotsContainer>
      </ScheduleFieldItem>
    </Wrapper>
  );
};

export default ClinicSchedule;
